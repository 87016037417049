import { ERROR_CODES } from "@/constants/axios";
import { useAuth } from "@/context/AuthContext";
import { AxiosError } from "axios";
import useToast from "./ui/useToast";

export const useOnError = () => {
  const { showToast } = useToast();
  const { logout } = useAuth();

  const handleError = <T extends Function>(
    error: Error,
    otherErrorCallback?: T
  ) => {
    if (error instanceof AxiosError) {
      const errorCode = error.response?.data?.errorCode;

      if (errorCode === ERROR_CODES.INVALID_ACCESS_TOKEN) {
        showToast({
          message: "세션이 만료되었습니다. 다시 로그인해주세요.",
          type: "error",
        });
        logout();
        return;
      }
    }

    if (otherErrorCallback) {
      otherErrorCallback(error);
      return;
    }

    showToast({
      message: "알 수 없는 오류가 발생했습니다.",
      type: "error",
    });
  };

  return { handleError };
};
