import { IntervieweeType } from "@/@types/interviewee";
import { LinkedInDetail } from "@/@types/linkedinDetail";
import { getInterviewees } from "@/api/interviewee";
import { GetIntervieweesResponse } from "@/api/interviewee/types";
import { QUERY_KEYS } from "@/constants/queryKey";
import { useQuery } from "@tanstack/react-query";

export type useGetIntervieweesItem = GetIntervieweesResponse[0] & {
  linkedinDetail: LinkedInDetail;
  type: IntervieweeType;
};

export const useGetInterviewees = () => {
  return useQuery<useGetIntervieweesItem[]>({
    queryKey: QUERY_KEYS.INTERVIEWEES,
    retry: false,
    queryFn: getInterviewees,
    refetchInterval: 1000 * 30,
  });
};
