interface DayFormat {
  year: number;
  month: number;
  day: number;
}

const formatStartEndDate = (experience: {
  starts_at: DayFormat | null;
  ends_at: DayFormat | null;
}) => {
  const period = experience?.ends_at
    ? `${experience?.starts_at?.year} - ${experience?.ends_at.year}`
    : `current`;

  return period;
};

export default formatStartEndDate;
