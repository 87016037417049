import { API_ROUTES } from "@/constants/routeName";
import { api } from "@/lib/axios";
import { parseLinkedInDetail } from "@/utils/api/parseLinkedInDetail";
import { determineIntervieweeType } from "@/utils/determineIntervieweeType";
import {
  CreateIntervieweeQuestionResponse,
  CreateIntervieweeRequest,
  CreateIntervieweeResponse,
  GetIntervieweesResponse,
  GetIntervieweeSummaryResponse,
  PatchIntervieweeDetailRequest,
  PatchIntervieweeDetailResponse,
  S3PresignedUrlResponse,
  UploadToS3Response,
} from "./types";
import axios from "axios";
import { useGetSummaryData } from "@/hooks/summary/useGetSummary";

export const createInterviewee = async (body: CreateIntervieweeRequest) => {
  const response = await api.post<CreateIntervieweeResponse>(
    API_ROUTES.INTERVIEWEE.CREATE_INTERVIEWEE,
    body
  );

  const parsedResponse = {
    ...response,
    linkedinDetail: parseLinkedInDetail(response.linkedinDetail),
  };

  return parsedResponse;
};

export const getInterviewees = async () => {
  const response = await api.get<GetIntervieweesResponse>(
    API_ROUTES.INTERVIEWEE.GET_INTERVIEWEES
  );

  const parsedData = response.map((interviewee) => ({
    ...interviewee,
    linkedinDetail: parseLinkedInDetail(interviewee.linkedinDetail),
    type: determineIntervieweeType(interviewee),
  }));

  return parsedData;
};

export const getIntervieweeSummary = async (id: number) => {
  const response = await api.get<GetIntervieweeSummaryResponse>(
    API_ROUTES.INTERVIEWEE.GET_INTERVIEWEE_SUMMARY(id)
  );

  const parsedData: useGetSummaryData = {
    ...response,
    linkedinDetail: parseLinkedInDetail(response.linkedinDetail),
  };

  return parsedData;
};

export const createIntervieweeQuestion = async (id: number) => {
  const response = await api.post<CreateIntervieweeQuestionResponse>(
    API_ROUTES.INTERVIEWEE.CREATE_QUESTION(id),
    {}
  );
  return response;
};

export const deleteInterviewee = async (id: number) => {
  await api.delete(API_ROUTES.INTERVIEWEE.DELETE_INTERVIEWEE(id));
};

export const patchIntervieweeDetail = async (
  intervieweeId: number,
  body: Partial<PatchIntervieweeDetailRequest>
) => {
  const response = await api.patch<PatchIntervieweeDetailResponse>(
    API_ROUTES.INTERVIEWEE.PATCH_DETAIL(intervieweeId),
    body
  );
  return response;
};

export const uploadToS3 = async (file: File, intervieweeId: number) => {
  const presigned_response = await api.post<S3PresignedUrlResponse>(
    API_ROUTES.INTERVIEWEE.GET_PRESIGNED_URL(intervieweeId),
    {}
  );

  const formData = new FormData();

  Object.keys(presigned_response.fields).forEach((key) => {
    formData.append(
      key,
      presigned_response.fields[key as keyof typeof presigned_response.fields]
    );
  });

  formData.append("file", file);

  await axios.post<UploadToS3Response>(presigned_response.url, formData);

  return presigned_response;
};
