import useGetIntervieweeIdParam from "@/hooks/summary/useIntervieweeId";
import { useGetSummaryProfileData } from "@/hooks/summary/useGetSummary";
import formatStartEndDate from "@/utils/format/formatStartEndDate";
import ExperienceItem, { ExperienceItemProps } from "../ExperienceItem";
import ContentBoxForSummary from "../ContentBoxForSummary";

const CareerSection = () => {
  const id = useGetIntervieweeIdParam();
  const linkedInDetail = useGetSummaryProfileData(id);
  if (!linkedInDetail) return null;

  const careerData: ExperienceItemProps[] | undefined =
    linkedInDetail.experiences?.map((item) => ({
      description: item.description,
      main: item.company,
      sub: item.title,
      period: formatStartEndDate({
        starts_at: item.starts_at,
        ends_at: item.ends_at,
      }),
      img_url: item.logo_url,
    }));

  return (
    <ContentBoxForSummary title="Career">
      <div className="flex flex-col gap-summary_experience_item_gap">
        {careerData?.map((data, index) => {
          return <ExperienceItem key={index} {...data} />;
        })}
      </div>
    </ContentBoxForSummary>
  );
};

export default CareerSection;
