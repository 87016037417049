// hooks/useFileUploader.ts
import { QUERY_KEYS } from "@/constants/queryKey";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import useModal from "../ui/useModal";
import useToast from "../ui/useToast";
import useGetIntervieweeIdParam from "./useIntervieweeId";
import usePatchIntervieweeDetail from "./usePatchIntervieweeDetail";
import useUploadAudioToS3 from "./useUploadAudioToS3";

const useFileUploader = () => {
  const [file, setFile] = useState<File | null>(null);
  const [textScript, setTextScript] = useState("");
  const [isFileScript, setIsFileScript] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const intervieweeId = useGetIntervieweeIdParam();

  const queryClient = useQueryClient();

  const { showToast } = useToast();
  const { closeModal } = useModal();

  const {
    mutateAsync: uploadAudioToS3Async,
    isPending: isUploadAudioToS3Pending,
  } = useUploadAudioToS3({});

  const {
    mutate: patchIntervieweeDetail,
    isPending: isPatchIntervieweeDetailLoading,
    isError: isPatchIntervieweeDetailError,
    isSuccess: isPatchIntervieweeDetailSuccess,
  } = usePatchIntervieweeDetail({
    onSuccess: (data, { intervieweeId, body }) => {
      closeModal();

      showToast({
        type: "success",
        message: "Successfully uploaded audio",
      });

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.INTERVIEW_SUMMARY(intervieweeId!),
      });
    },
    onError: (error) => {
      showToast({
        type: "error",
        message: "Failed to upload audio. try again later",
      });
      setErrorMsg("Failed to upload audio. try again later");
    },
  });

  const isLoading = isFileScript
    ? isUploadAudioToS3Pending || isPatchIntervieweeDetailLoading
    : isPatchIntervieweeDetailLoading;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  }, []);

  const onClickDeleteBtn = () => {
    setFile(null);
  };

  const onClickGenerateBtn = async () => {
    if (isFileScript && !file) {
      setErrorMsg("Please select a file to upload");
      return;
    }

    if (!isFileScript && !textScript) {
      setErrorMsg("Please enter a script to upload");
      return;
    }

    setErrorMsg("");

    const content = isFileScript ? file : textScript;

    if (isFileScript) {
      try {
        const response = await uploadAudioToS3Async({
          file: content as File,
          intervieweeId,
        });

        patchIntervieweeDetail({
          intervieweeId,
          body: {
            interviewFile: response.url + response.fields.key,
          },
        });
      } catch (error) {
        showToast({
          type: "error",
          message: "Failed to upload audio. try again later",
        });
        setErrorMsg("Failed to upload audio. try again later");
      }
    } else {
      patchIntervieweeDetail({
        intervieweeId,
        body: {
          interviewScript: content as string,
        },
      });
    }
  };

  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextScript(e.target.value);
  };

  const onClickSelectInFinderBtn = () => {
    document.getElementById("fileInput")?.click();
  };

  const handleChangeToFileMode = (isFileScript: boolean) => {
    setErrorMsg("");
    if (!isFileScript) {
      setTextScript("");
    } else {
      setFile(null);
    }

    setIsFileScript(isFileScript);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "audio/amr": [".amr"],
      "audio/flac": [".flac"],
      "audio/x-m4a": [".m4a"],
      "audio/mp3": [".mp3"],
      "video/mp4": [".mp4"],
      "audio/ogg": [".ogg"],
      "video/webm": [".webm"],
      "audio/wav": [".wav"],
    },
  });

  return {
    file,
    textScript,
    isFileScript,
    errorMsg,
    isLoading,
    isDragActive,
    handleChangeToFileMode,
    onClickDeleteBtn,
    onClickGenerateBtn,
    onClickSelectInFinderBtn,
    onChangeTextArea,
    getRootProps,
    getInputProps,
  };
};

export default useFileUploader;
