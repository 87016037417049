import Typography from "@/components/ui/Typography";

const ItemTextSection = ({
  title,
  subtitle,
}: {
  title: string | null;
  subtitle: string;
}) => {
  return (
    <div className="flex flex-col justify-center gap-1">
      <Typography size={"small2"} weight={"normal"} fontColor={"secondary"}>
        {subtitle}
      </Typography>
      <Typography size={"content2"} weight={"bold"} fontColor={"primary"}>
        {title}
      </Typography>
    </div>
  );
};

export default ItemTextSection;
