import StyledRect from "@/components/ui/StyledRect";
import Typography from "@/components/ui/Typography";
import { ReactComponent as Location } from "#/public/icon/location.svg";
import { useGetSummaryCompany } from "@/hooks/summary/useGetSummary";
import useGetIntervieweeIdParam from "@/hooks/summary/useIntervieweeId";

const CompanyBasicInfo = () => {
  const id = useGetIntervieweeIdParam();

  const company = useGetSummaryCompany(id);

  if (!company) return null;

  const { basicInfo } = company;

  return (
    <>
      <div className="flex gap-xlarge_gap">
        <div className="flex-1 flex gap-10 overflow-hidden">
          <StyledRect
            variant={"muted"}
            className="w-[120px] h-[120px]"
            rounded={"xl"}
          ></StyledRect>
          <div className="flex flex-col justify-center flex-1 overflow-hidden gap-3">
            <Typography
              size={"heading4"}
              fontColor={"heading"}
              className="overflow-hidden text-nowrap text-ellipsis"
            >
              {basicInfo.name}
            </Typography>
            <div className="flex gap-2 items-center">
              <Location />
              <Typography
                size={"content2"}
                fontColor={"tertiary"}
                weight={"normal"}
                className="overflow-hidden text-nowrap text-ellipsis"
              >
                {basicInfo.location}
              </Typography>
            </div>
          </div>
        </div>
        {/* <div className="flex-1 flex items-start overflow-hidden overflow-y-auto">
          <Typography
            size={"content2"}
            fontColor={"heading"}
            weight={"normal"}
            lineHeight={"normal"}
          >
            {basicInfo.description}
          </Typography>
        </div> */}
      </div>
    </>
  );
};

export default CompanyBasicInfo;
