import { useMutation, useQueryClient } from "@tanstack/react-query";
import useToast from "../ui/useToast";
import { deleteInterviewee } from "@/api/interviewee";
import { QUERY_KEYS } from "@/constants/queryKey";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "@/constants/routeName";

export const useDeleteInterviewee = () => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const navigate = useNavigate();

  return useMutation({
    mutationFn: (id: number | undefined) => {
      return deleteInterviewee(id!);
    },
    onSuccess: (data, id) => {
      navigate(PAGE_ROUTES.HOME); // 인터뷰이 삭제 후 홈으로 이동

      //   queryClient.invalidateQueries({
      //     queryKey: QUERY_KEYS.INTERVIEWEES,
      //   });

      queryClient.removeQueries({
        queryKey: QUERY_KEYS.INTERVIEW_SUMMARY(id!),
      }); // 삭제된 인터뷰이의 세부 정보 캐시 제거

      showToast({
        message: "인터뷰이가 성공적으로 삭제되었습니다.",
        type: "success",
      });
    },
    onError: (error) => {
      showToast({
        message: "인터뷰이를 삭제하는데 실패했습니다. 다시 시도해주세요.",
        type: "error",
      });
    },
  });
};
