import { cn } from "@/utils";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { Link } from "react-router-dom";

const buttonVariants = cva(
  // "inline-flex items-center justify-center whitespace-nowrap rounded-text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:bg-zinc-600",
  "box-border inline-flex items-center justify-center whitespace-nowrap rounded-veda_border_radius text-md font-medium leading-none disabled:pointer-events-none disabled:bg-background-gray03 cursor-pointer",
  {
    variants: {
      variant: {
        default:
          "bg-background-green02 text-background-white font-semibold disabled:bg-background-gray05 disabled:border-background-gray04 disabled:border-2 disabled:text-background-gray02",
        secondary: "font-semibold bg-background-blue",
        outline_signup:
          "border border-[1px] border-background-green02 bg-background text-background-green02",
        outline_secondary:
          "border border-[1px] border-primary bg-background-gray05 text-primary",
        outline_destructive:
          "border border-[1px] border-destructive text-destructive",
        outline_destructive_fill:
          "border border-[1px] border-destructive bg-background-red02 text-background-white",
        outline_black:
          "border border-[1px] border-background-gray01 bg-background-gray06 text-background-gray01",
        outline_gray:
          "border border-[1px] border-background-gray04 bg-background-gray05 text-background-gray02",
        gray: "bg-background-gray04 text-background-gray02",
        transparent: "bg-transparent text-background-white",
        white: "bg-background-white text-background-black",
        gradient_background:
          "bg-create-background bg-center bg-cover bg-no-repeat border-[2px] border-background-white",
      },
      size: {
        default: "px-[20px] py-[16px]",
        lg: "px-[26px] py-[22px]",
        icon: "h-10 w-10",
        icon_sm: "h-5 w-5",
        icon_lg: "h-11 w-11",
      },
      rounded: {
        normal: "rounded-vexa_border_radius",
        xl: "rounded-veda_border_radius_xl",
        xl4: "rounded-veda_border_radius_4xl",
        full: "rounded-full",
      },
      hover: {
        default:
          "opacity-[0.9] hover:opacity-100 transition-opacity duration-200",
        none: "opacity-100 hover:opacity-100",
      },
    },

    defaultVariants: {
      variant: "default",
      size: "default",
      hover: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  isLoading?: boolean;
  children?: React.ReactNode;
  loadingSize?: "sm" | "lg";
  to?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      isLoading,
      children,
      rounded,
      hover,
      disabled,
      to,
      ...props
    },
    ref
  ) => {
    if (to) {
      return (
        <Link
          to={to}
          className={cn(
            buttonVariants({ variant, size, rounded, className, hover })
          )}
        >
          {isLoading ? (
            <div className="spinner w-4 h-4 border-2 rounded-full"></div>
          ) : (
            children
          )}
        </Link>
      );
    }

    return (
      <button
        className={cn(buttonVariants({ variant, size, rounded, className }))}
        disabled={disabled || isLoading}
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <div className="spinner w-4 h-4 border-2 rounded-full"></div>
        ) : (
          children
        )}
      </button>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
