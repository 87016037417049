import useGetIntervieweeIdParam from "@/hooks/summary/useIntervieweeId";
import { useGetSummaryCompany } from "@/hooks/summary/useGetSummary";
import ContentBox from "../../ui/ContentBox";
import ContentBoxForSummary from "../ContentBoxForSummary";
import CompanyBasicInfo from "./CompanyBasicInfo";
import CompanyStatBox from "./CompanyStatBox";
import CompanyPeopleBox from "./CompanyPeopleBox";

export const CompanySection = () => {
  const id = useGetIntervieweeIdParam();

  const company = useGetSummaryCompany(id);

  if (!company) return null;

  return (
    <ContentBox className="flex-1" padding={"summary_content_container"}>
      <CompanyBasicInfo />
      <div className="mt-6" />

      <div className="flex flex-col gap-large_gap">
        <ContentBoxForSummary title="overview">
          {company.basicInfo.description}
        </ContentBoxForSummary>
        <CompanyStatBox
          stats={{
            metric: [
              { name: "Founded Date", value: company.basicStats.founded },
              { name: "Employees", value: company.basicStats.industry },
              { name: "Investment", value: company.basicStats.revenue },
              { name: "Valuation", value: company.basicStats.size },
            ],
          }}
        />
        <div className="flex gap-summary_company_item">
          <CompanyPeopleBox
            people={company.people}
            title="Co-Founders"
          ></CompanyPeopleBox>
          <CompanyPeopleBox
            people={company.people}
            title="Executives"
          ></CompanyPeopleBox>
        </div>
        <div className="flex gap-summary_company_item">
          <CompanyPeopleBox
            people={company.people}
            title="Competitors - Product"
          ></CompanyPeopleBox>
          <CompanyPeopleBox
            people={company.people}
            title="-"
          ></CompanyPeopleBox>
        </div>
      </div>
    </ContentBox>
  );
};

export default CompanySection;
