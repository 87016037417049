// hooks/auth/useRegister.ts
import { useMutation } from "@tanstack/react-query";
import { register } from "@/api/auth";

const useRegister = () => {
  return useMutation<
    {
      accessToken: string;
    },
    Error,
    {
      email: string;
      name: string;
      password: string;
    }
  >({
    mutationFn: register,
  });
};

export default useRegister;
