// hooks/useLogin.ts
import { login } from "@/api/auth";
import { useMutation } from "@tanstack/react-query";

const useLogin = () => {
  return useMutation<
    {
      accessToken: string;
    },
    Error,
    {
      email: string;
      password: string;
    }
  >({
    mutationFn: login,
  });
};

export default useLogin;
