import { BasicInterviewee, IntervieweeType } from "@/@types/interviewee";
import { GetIntervieweesResponse } from "@/api/interviewee/types";

export const determineIntervieweeType = (
  interviewee: GetIntervieweesResponse[0]
): IntervieweeType => {
  if (!interviewee.isKeyMomentsExist) {
    return IntervieweeType.CREATING;
  }

  if (!interviewee.interviewFile && !interviewee.isInterviewScriptExist) {
    return IntervieweeType.CREATED_NOT_AUDIO_UPLOADED;
  }

  return IntervieweeType.CREATED_AUDIO_UPLOADED;
};
