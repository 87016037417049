import Footer from "@/components/Footer";
import Header from "@/components/Header";
import CompanySection from "@/components/summary/company/CompanySection";
import FixedNavBtn from "@/components/summary/FixedNavBtn";
import KeyMomentsSection from "@/components/summary/keyMoments/KeyMomentsSection";
import PeopleSection from "@/components/summary/people/PeopleSection";
import RecapSection from "@/components/summary/recap/RecapSection";
import SummaryActionBtn from "@/components/summary/SummaryActionBtn";
import UploadAudioModal from "@/components/summary/UploadAudioModal";
import { Button } from "@/components/ui/Button";
import { PageLayout } from "@/components/ui/Layout";
import { PAGE_ROUTES } from "@/constants/routeName";
import { useDeleteInterviewee } from "@/hooks/summary/useDeleteInterviewee";
import { useGetSummary } from "@/hooks/summary/useGetSummary";
import useInitializeIntervieweeId from "@/hooks/summary/useInitializeIntervieweeId";
import useScrollTopOnMount from "@/hooks/ui/useScrollTopOnMount";
import useIntervieweeIdParam from "@/hooks/useIntervieweeIdParam";
import useModalStore from "@/store/modalStore";
import { useNavigate } from "react-router-dom";
import ErrorPage from "../@common/ErrorPage";
import LoadingPage from "../@common/LoadingPage";

const SummaryPage = () => {
  useScrollTopOnMount();

  const navigate = useNavigate();

  const id = useIntervieweeIdParam();

  useInitializeIntervieweeId({
    id,
  });

  const { openModal, visible } = useModalStore();

  const {
    data: summaryData,
    error,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useGetSummary(id);

  const { mutate: deleteInterviewee, isPending } = useDeleteInterviewee();

  if (!id) {
    return (
      <ErrorPage
        error={{
          message: "잘못된 접근입니다.",
        }}
        buttonLabel={"Go back to home"}
        onRetry={() => navigate(PAGE_ROUTES.HOME)}
      />
    );
  }

  if (isLoading) {
    return <LoadingPage></LoadingPage>;
  }

  if (isError || !summaryData) {
    return (
      <ErrorPage
        onRetry={refetch}
        error={{
          message: error?.message || "Failed to load summary",
        }}
      />
    );
  }

  const onClickDeleteBtn = () => {
    deleteInterviewee(id);
  };

  const onClickArrow = () => {
    navigate(PAGE_ROUTES.HOME);
  };

  const onClickUploadBtn = () => {
    openModal({
      component: <UploadAudioModal />,
    });
  };

  const onClickGenerateArticle = () => {
    navigate(PAGE_ROUTES.ARTICLE.CREATE(id));
  };

  return (
    <>
      <Header />
      <PageLayout
        title={
          <PageLayout.Title onClickArrow={onClickArrow}>
            <div className="flex-1 flex justify-between items-center">
              <div className="flex items-center gap-3">
                <PageLayout.TitleText>Summary of</PageLayout.TitleText>
                <PageLayout.TitleText fontColor={"green_primary"}>
                  {summaryData.linkedinDetail.full_name}
                </PageLayout.TitleText>
              </div>
              <SummaryActionBtn
                isFetching={isFetching}
                summaryData={summaryData}
                onClickGenerateArticle={onClickGenerateArticle}
                onClickUploadBtn={onClickUploadBtn}
              />
            </div>
          </PageLayout.Title>
        }
      >
        {/* {summaryData.recap.sections.length > 0 && <RecapSection />} */}
        <div className="flex gap-default_gap">
          <PeopleSection />
          <CompanySection />
        </div>
        <div className="mt-24" />
        <KeyMomentsSection keyMoments={summaryData.keyMoments} />
        <div className="mt-24" />
        <div className="flex justify-center">
          <Button
            variant={"outline_destructive"}
            className="w-[150px]"
            onClick={onClickDeleteBtn}
            isLoading={isPending}
          >
            Delete
          </Button>
        </div>
      </PageLayout>
      {!visible && <FixedNavBtn />}
      <Footer />
    </>
  );
};

export default SummaryPage;
