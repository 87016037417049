import React from "react";
import Typography from "../ui/Typography";

const ArticleContentLayout = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div>
      <Typography size={"heading4"} weight={"bold"} fontColor={"heading"}>
        {title}
      </Typography>
      <div className="mt-8" />
      {children}
    </div>
  );
};

export default ArticleContentLayout;
