import { ReactComponent as Pencil } from "#/public/icon/greenPencil.svg";

import { Button } from "./Button";
import Typography from "./Typography";

export const SummarySection = ({
  children,
  title,
  onClickEdit,
  isShowEdit = true,
}: {
  children: React.ReactNode;
  title: string;
  onClickEdit?: () => void;
  isShowEdit?: boolean;
}) => {
  return (
    <section className="mb-20 flex flex-col gap-large_gap">
      <div className="flex justify-between items-center">
        <Typography size={"heading3"} fontColor={"heading"} weight={"semibold"}>
          {title}
        </Typography>
        {isShowEdit && (
          <Button
            variant={"outline_secondary"}
            size={"icon"}
            rounded={"xl"}
            onClick={onClickEdit}
          >
            <Pencil className="text-background-green01" />
          </Button>
        )}
      </div>
      {children}
    </section>
  );
};

export const QuestionSection = ({
  children,
  title,
  onClickEdit,
  isShowEdit = true,
}: {
  children: React.ReactNode;
  title: string;
  onClickEdit?: () => void;
  isShowEdit?: boolean;
}) => {
  return (
    <section className="mb-20 flex flex-col gap-large_gap">
      <div className="flex justify-between items-center">
        <Typography size={"content1"} fontColor={"heading"} weight={"semibold"}>
          {title}
        </Typography>
        {/* {isShowEdit && (
          <Button
            variant={"outline_secondary"}
            size={"icon"}
            rounded={"xl"}
            onClick={onClickEdit}
          >
            <Pencil className="text-background-green01" />
          </Button>
        )} */}
      </div>
      {children}
    </section>
  );
};
