import { Button } from "@/components/ui/Button";
import { Input } from "@/components/ui/Input";
import Typography from "@/components/ui/Typography";
import useLinkForm from "@/hooks/home/useLinkForm";
import React from "react";
import LinkUrlItem from "./LinkUrlItem";

const ReferenceLinkForm = () => {
  const {
    newReference,
    setNewReference,
    handleSubmitReference,
    linkForm,
    handleRemoveReference,
  } = useLinkForm();

  return (
    <div className="flex-1 flex flex-col overflow-auto">
      <form className="flex gap-3" onSubmit={handleSubmitReference}>
        <Input
          className="flex-1"
          placeholder="www.medium.com/"
          type="text"
          id="reference"
          value={newReference}
          onChange={(e) => setNewReference(e.target.value)}
        />
        <Button variant={"secondary"} size={"lg"} className="w-[15%]">
          <Typography size={"content2"}>Add</Typography>
        </Button>
      </form>
      <div className="mt-content_default" />
      <div className="flex-1 w-[80%] flex items-start gap-2 flex-wrap overflow-auto content-start">
        {linkForm.references.map((reference, index) => (
          <LinkUrlItem
            key={index + reference}
            text={reference}
            onClickDelete={() => handleRemoveReference(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ReferenceLinkForm;
