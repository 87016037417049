export const QUERY_KEYS = {
  INTERVIEWEES: ["INTERVIEWEES"],
  INTERVIEW_SUMMARY: (intervieweeId: number) => [
    "INTERVIEW_SUMMARY",
    intervieweeId,
  ],
  ARTICLES: (intervieweeId: number) => ["ARTICLES", intervieweeId],
  ARTICLE_DETAIL: (articleId: number) => ["ARTICLE_DETAIL", articleId],
  ARTICLE_PROMPTS: ["ARTICLE_PROMPTS"],
} as const;
