// components/Spinner.tsx
import { cn } from "@/utils";
import React from "react";

interface SpinnerProps {
  width?: string;
  height?: string;
  color?: string;
  borderWidth?: string;
  borderColor?: string;
}

const Spinner: React.FC<SpinnerProps> = ({
  width = "w-12", // 기본 값: 3rem
  height = "h-12", // 기본 값: 3rem
  color = "border-t-background-blue", // 기본 색상
  borderWidth = "border-4", // 기본 테두리 두께
  borderColor = "border-gray-200", // 기본 테두리 색상
}) => {
  return (
    <div
      aria-label="loading"
      className={cn(
        "ease-linear rounded-full loader",
        width,
        height,
        borderWidth,
        borderColor,
        color
      )}
    ></div>
  );
};

export default Spinner;
