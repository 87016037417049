import { useEffect } from "react";
import { useCreateIntervieweeQuestion } from "./useCreateQuestion";
import { useGetSummary } from "./useGetSummary";

// 캐시가 없으면 바로 fetch하니까 loading 걸리겠고, 질문 있으면 보여주고 없으면 질문 생성중...띄워주면서 createQuestion 호출.
// 캐시가 있으면? question 있으면 보여주고 fetch, 갱신하고 create는 안함. question 없으면 로딩 보여주면서 fetch, createQuestion 호출해서 집어넣고 보여줌.
// 즉 최신으로 갱신한다음에, question이 없으면 생성하고, 있으면 그걸 보여주는 식.

export const useQuestions = (id: number | undefined) => {
  const { data, isLoading, isError, refetch, isFetching } = useGetSummary(id);

  const {
    mutate: createQuestion,
    isPending: isCreateQuestionPending,
    isError: isCreateQuestionError,
  } = useCreateIntervieweeQuestion();

  const updateQuestions = async () => {
    try {
      const response = await refetch();

      if (response && response.data && response.data.questions.length === 0) {
        createQuestion(id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateQuestions();
  }, []);

  const questions = data?.questions;

  const intervieweeName =
    data?.linkedinDetail.full_name || data?.linkedinDetail.last_name;

  return {
    questions,
    intervieweeName,
    isLoading,
    isError,
    refetch,
    isCreateQuestionPending,
    isCreateQuestionError,
    createQuestion,
  };
};
