import { useParams } from "react-router-dom";

const useIntervieweeIdParam = () => {
  const { interviewId } = useParams();

  const id = interviewId ? parseInt(interviewId) : undefined;

  return id;
};

export default useIntervieweeIdParam;
