import React from "react";
import Typography from "./ui/Typography";
import Container from "./ui/Container";

const Footer = () => {
  return (
    <div className="h-footer py-10 bg-background-gray06">
      <Container>
        <div className="flex flex-col items-start justify-between h-[192px]">
          <Typography size={"heading"} weight={"bold"} fontColor={"primary"}>
            VEDA
          </Typography>
          <Typography size={"content1"} weight={"normal"} fontColor={"primary"}>
            2024 EO Studio. All Rights Reserved
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
