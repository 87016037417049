import Typography from "@/components/ui/Typography";
import CreateInterviewBtn from "./StartInterviewSection/CreateInterviewBtn";
import LinkSection from "./StartInterviewSection/LinkSection";
import LinkedInInputForm from "./StartInterviewSection/LinkedInInputForm";
import ReferenceLinkForm from "./StartInterviewSection/ReferenceLinkForm";

const StartInterviewSection = ({
  onInterviewCreated,
}: {
  onInterviewCreated: () => void;
}) => {
  return (
    <div className="mx-container_x flex flex-col min-h-full_screen_with_header">
      <div className="mt-20" />
      <Typography size={"heading"} weight={"semibold"} fontColor={"primary"}>
        Start New Interview
      </Typography>
      <div className="mt-12" />
      <div className="flex-1 flex flex-col gap-16 overflow-auto">
        <LinkSection
          title={"Enter Linkedin Address."}
          number={1}
          description="Please enter the Linkedin Address of the interviewee. "
        >
          <div className="w-[40%]">
            <div className="mt-12" />
            <LinkedInInputForm />
          </div>
        </LinkSection>
        <LinkSection
          title={"Add related links \n about the interviewee."}
          description="Add related links to articles, newsletters, youtube video, etc. about the interviewee."
          number={2}
          className="flex-1"
        >
          <div className="w-[40%] flex flex-col self-stretch">
            <div className="mt-12" />
            <ReferenceLinkForm />
          </div>
        </LinkSection>
      </div>
      <CreateInterviewBtn onInterviewCreated={onInterviewCreated} />
    </div>
  );
};

export default StartInterviewSection;
