import React from "react";
import Typography, { TypographyProps } from "../Typography";
import { cn } from "@/utils";
import { TypoModal } from "./TypoModal";

interface TypographyWithFootNoteProps extends TypographyProps {
  footNote: string;
}

export const TypographyWithFootNote = ({
  children,
  className,
  footNote,
  style,
  ...props
}: TypographyWithFootNoteProps) => {
  const [isModalVisible, setModalVisible] = React.useState(false); // 모달의 표시 상태

  const handleMouseEnter = () => {
    setModalVisible(true); // 마우스가 들어왔을 때 모달 표시
  };

  const handleMouseLeave = () => {
    setModalVisible(false); // 마우스가 나갔을 때 모달 숨김
  };

  const ModalComponent = (
    <TypoModal className="top-full left-0 w-80" children={footNote} />
  );

  return (
    <div className={cn(className, "flex gap-2")} style={style} {...props}>
      <Typography size={"content2"} lineHeight={"loose"}>
        {children}
      </Typography>
      <div
        className="flex items-center relative cursor-pointer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Typography size={"small3"} lineHeight={"loose"}>
          [source]
        </Typography>
        {isModalVisible && ModalComponent}
      </div>
    </div>
  );
};

export default TypographyWithFootNote;
