import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/utils";

const inputVariants = cva(
  "box-border inline-flex items-center justify-center whitespace-nowrap rounded-veda_border_radius text-md font-medium leading-none",
  {
    variants: {
      variant: {
        primary:
          "bg-input-background placeholder-input-placeholder text-input-foreground caret-background-green01",
      },
      inputSize: {
        default: "px-[24px] py-[20px]", // 원래 28이었는데 cursor고려해서 수정.
        sm: "px-[14px] py-[10px]",
        lg: "px-[42px] py-[30px]",
      },
    },
    defaultVariants: {
      variant: "primary",
      inputSize: "default",
    },
  }
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  minWidth?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, inputSize, minWidth, ...props }, ref) => {
    return (
      <div
        className={cn(inputVariants({ variant, inputSize, className }))}
        style={{ minWidth }}
      >
        <input
          ref={ref}
          className={cn(
            "w-full h-full bg-transparent border-none outline-none",
            "font-normal tracking-[-0.48px]",
            "focus:ring-0 focus:ring-offset-0",
            "text-[20px] leading-none p-0 m-0"
          )}
          {...props}
        />
      </div>
    );
  }
);

Input.displayName = "Input";

export { Input, inputVariants };
