type articlePromptName =
  | "article_generation_FullyCustomized"
  | "article_genertation_Interviewee"
  | "article_genertation_Advice";

export const articlePromptMap: {
  [key in articlePromptName]: {
    topic: string;
    subText: string;
    order: number | null;
    name?: string;
  };
} = {
  article_generation_FullyCustomized: {
    topic: "Fully Customized",
    subText: "Manual Generation",
    order: 2,
  },
  article_genertation_Interviewee: {
    topic: "Interviewee’s Career",
    subText: "Focus on",
    order: 0,
  },
  article_genertation_Advice: {
    topic: "Advice for Founders",
    subText: "Focus on",
    order: 1,
  },
};

Object.entries(articlePromptMap).forEach(([key, value]) => {
  value.name = key;
});
