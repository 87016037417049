import { ReactComponent as AiSparkleIcon } from "#/public/icon/ai_sparkle.svg";
import { ReactComponent as AiSparkleIconDisabled } from "#/public/icon/ai_sparkle_disabled.svg";
import { Button } from "@/components/ui/Button";
import Typography from "@/components/ui/Typography";
import useLinkForm from "@/hooks/home/useLinkForm";
import { useSubmitLinkForm } from "@/hooks/home/useSubmitLinkForm";
import useToast from "@/hooks/ui/useToast";
import { cn } from "@/utils";

const CreateInterviewBtn = ({
  onInterviewCreated,
}: {
  onInterviewCreated: () => void;
}) => {
  const { showToast } = useToast();

  const {
    linkForm,
    isCreateBtnDisabled,
    createButtonErrorMsg,
    validateLinkForm,
    resetAll,
  } = useLinkForm();

  const { isPending, mutate: submitLinkForm } = useSubmitLinkForm({
    onInterviewCreatedSuccess: () => {
      onInterviewCreated();
      resetAll();
    },
  });

  const onClickCreateBtn = () => {
    const errorMessage = validateLinkForm();

    if (errorMessage) {
      showToast({
        message: errorMessage,
        type: "error",
      });
      return;
    }

    submitLinkForm(linkForm);
  };

  return (
    <div className="flex flex-col justify-center items-center mt-12 mb-20 gap-4 relative">
      <Button
        variant={"default"}
        className={cn("w-[250px] h-[72px] flex justify-center gap-2 pl-8", {
          "bg-create-background bg-center bg-cover bg-no-repeat":
            !isCreateBtnDisabled,
        })}
        aria-label="create-interview-button"
        isLoading={isPending}
        disabled={isCreateBtnDisabled}
        onClick={onClickCreateBtn}
      >
        <Typography
          size={"content1"}
          fontColor={isCreateBtnDisabled ? "disabled" : "primary"}
        >
          Create
        </Typography>
        {isCreateBtnDisabled ? <AiSparkleIconDisabled /> : <AiSparkleIcon />}
      </Button>
      <Typography
        weight={"normal"}
        size={"content2"}
        fontColor={"destructive"}
        className="absolute top-[120%]"
      >
        {createButtonErrorMsg}
      </Typography>
    </div>
  );
};

export default CreateInterviewBtn;
