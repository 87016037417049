// AuthProvider.tsx
import { STORAGE_KEY } from "@/constants/localStorage";
import { PAGE_ROUTES } from "@/constants/routeName";
import { getItem, removeItem, setItem } from "@/lib/localStorage";
import { useUserStore } from "@/store/userStore";
import { delay } from "@/utils/api/delay";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface LoginData {
  accessToken: string;
  email: string;
}

interface AuthContextType {
  user: User | null;
  login: (response: LoginData) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    user,
    setUser,
    resetState: resetUserState,
  } = useUserStore((state) => state);

  const [initialLoad, setInitialLoad] = useState(true);

  const navigate = useNavigate();

  const login = (data: LoginData) => {
    setItem(STORAGE_KEY.ACCESS_TOKEN, data.accessToken);
    setItem(STORAGE_KEY.EMAIL, data.email);
    setUser({
      email: data.email,
    });
  };

  const logout = () => {
    resetUserState();
    removeItem(STORAGE_KEY.ACCESS_TOKEN);
    removeItem(STORAGE_KEY.EMAIL);
    navigate(PAGE_ROUTES.LOGIN);
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        if (process.env.NODE_ENV === "test") {
          await delay(100);
        }

        const token = getItem(STORAGE_KEY.ACCESS_TOKEN);
        const email = getItem(STORAGE_KEY.EMAIL);

        if (!token) {
          throw new Error("No token");
        }

        if (!email) {
          throw new Error("No email");
        }

        setUser({
          email,
        });
      } catch (err) {
        logout();
      } finally {
        setInitialLoad(false);
      }
    };

    initialize();
  }, []);

  if (initialLoad) {
    return <div>로딩중...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};
