import ContentBox from "@/components/ui/ContentBox";
import Typography from "@/components/ui/Typography";

const CompanyStatBoxItem = ({
  title,
  content,
}: {
  title: string;
  content?: string | number;
}) => {
  return (
    <ContentBox
      type={"summary_content"}
      className="flex flex-col justify-between gap-xsmall_gap"
      padding={"sumamry_company_stat_box"}
    >
      <Typography
        size={"small2"}
        fontColor={"heading"}
        weight={"medium"}
        letterSpacing={"tight"}
      >
        {title}
      </Typography>
      <Typography
        size={"heading4"}
        className="overflow-hidden text-ellipsis"
        fontColor={"green_primary"}
        weight={"medium"}
      >
        {content || "-"}
      </Typography>
    </ContentBox>
  );
};

export default CompanyStatBoxItem;
