import { Article } from "@/@types/article";
import useModal from "@/hooks/ui/useModal";
import LoadingPage from "@/pages/@common/LoadingPage";
import { formatISOStringToCustomDate } from "@/utils/format/formatDate";
import { Button } from "../ui/Button";
import ContentBox from "../ui/ContentBox";
import Typography from "../ui/Typography";

const ArticleListItemOnLoading = () => {
  return (
    <ContentBox
      type={"content_interview"}
      padding={"article_list_item"}
      className="h-[360px] flex flex-col"
    >
      <Typography size={"heading4"} fontColor={"primary"}></Typography>
      <div className="mt-2" />

      <div className="flex-1 overflow-hidden">
        <LoadingPage isRelative loadingText="generating article..." />
      </div>
    </ContentBox>
  );
};

const ArticleListItem = ({
  id,
  title,
  content,
  createdAt,
  user: { name },
}: Article) => {
  const { openModal } = useModal();

  if (!title || !content) {
    return <ArticleListItemOnLoading />;
  }

  const onClickView = () => {
    openModal({
      component: (
        <div className="w-[800px] h-[600px] bg-background-gray05 p-12 flex flex-col rounded-sm">
          <Typography size={"heading4"} fontColor={"primary"}>
            {title}
          </Typography>
          <div className="mt-6" />
          <div className="flex-1 overflow-auto">
            {content.split("\n\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
                <br />
              </span>
            ))}
          </div>
        </div>
      ),
    });
  };

  return (
    <ContentBox
      type={"content_interview"}
      padding={"article_list_item"}
      className="h-[360px] flex flex-col"
    >
      <Typography
        size={"heading4"}
        fontColor={"primary"}
        className="text-nowrap overflow-hidden text-ellipsis"
      >
        {title}
      </Typography>
      <div className="mt-2" />
      <div className="flex items-center gap-6">
        <Typography size={"small1"} fontColor={"tertiary"}>
          {formatISOStringToCustomDate(createdAt)}
        </Typography>
        <Typography size={"small1"} fontColor={"blue"}>
          {name}
        </Typography>
      </div>
      <div className="mt-6" />
      <div className="flex-1 overflow-hidden">
        <Typography
          size={"small2"}
          weight={"normal"}
          lineHeight={"snug"}
          fontColor={"primary"}
          className=""
        >
          {content}
        </Typography>
      </div>
      <div className="mt-6" />
      <div className="flex gap-2.5">
        <Button variant={"white"} className="flex-1" onClick={onClickView}>
          <Typography size={"small1"} weight={"semibold"}>
            View
          </Typography>
        </Button>
        <Button variant={"secondary"} className="flex-1">
          <Typography size={"small1"} weight={"semibold"}>
            Publish
          </Typography>
        </Button>
      </div>
    </ContentBox>
  );
};

export default ArticleListItem;
