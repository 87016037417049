import useIntervieweeIdStore from "@/store/intervieweeIdStore";
import { useEffect } from "react";

const useInitializeIntervieweeId = ({ id }: { id?: number }) => {
  const { setIntervieweeId, reset: resetIntervieweeId } =
    useIntervieweeIdStore();

  useEffect(() => {
    if (!id) return;
    setIntervieweeId(id);

    return () => {
      resetIntervieweeId();
    };
  }, [id, resetIntervieweeId, setIntervieweeId]);
};

export default useInitializeIntervieweeId;
