import { cn } from "@/utils";
import { cva, VariantProps } from "class-variance-authority";
import React from "react";
import { Link } from "react-router-dom";

export const ContentBoxVariants = cva(
  "rounded-veda_border_radius border-[1px] box-border",
  {
    variants: {
      type: {
        content: "bg-background-gray06 border-background-gray05 p-10",
        content_key_moments:
          "bg-background-gray05 border-background-gray04 p-6",
        content_interview: "bg-background-gray06 border-background-gray04 p-10",
        item_destructive:
          "border-destructive border-2 bg-background-gray05 px-6 py-4",
        item_destructive_not_created:
          "border-destructive border-2 bg-background-gray05 animation-interview-item-not-created px-6 py-4",
        item_complete:
          "border-primary border-2 bg-background-green03 px-6 py-4",
        item_normal:
          "border-background-gray04 border-2 bg-background-gray06 px-6 py-4",
        white: "bg-background-white border-background-gray02 px-5 py-4",
        summary_content: "bg-background-gray05 border-none",
        summary_content_border: "bg-background-gray06 border-background-gray05",
        content_article_topic:
          "border-background-gray05 border-2 bg-background-green05 px-6 py-4",
        content_article_topic_selected:
          "border-primary border-2 bg-background-green03 px-6 py-4",
      },
      padding: {
        p10: "p-10",
        p6: "p-6",
        p4: "px-6 py-4",
        p9: "py-9 px-10",
        key_moments: "px-5 py-4",
        sumamry_company_stat_box: "px-6 py-5",
        summary_content_container: "p-4",
        summary_uploaded_file: "px-4 py-3",
        article_list_item: "px-9 py-6",
        article_topic_btn: "px-6 py-9",
      },
    },
    defaultVariants: {
      type: "content",
    },
  }
);

interface ContentBoxProps extends VariantProps<typeof ContentBoxVariants> {
  children?: React.ReactNode;
  className?: string;
  href?: string;
  onClick?: () => void;
  href_target?: "_blank" | "_self" | "_parent" | "_top";
}

const ContentBox = ({
  children,
  className,
  href,
  onClick,
  type,
  padding,
  href_target,
}: ContentBoxProps) => {
  const contentBoxClass = cn(
    ContentBoxVariants({
      type,
      padding,
    }),
    className
  );

  if (href) {
    return (
      <Link
        to={href}
        target={href_target}
        className={contentBoxClass}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }

  return (
    <div onClick={onClick} className={contentBoxClass}>
      {children}
    </div>
  );
};

export default ContentBox;
