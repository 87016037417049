import { SummarySection } from "@/components/ui/Section";
import KeyMomentsBox from "./KeyMomentsBox";
import { KeyMoment } from "@/@types/interviewee";

const KeyMomentsSection = ({ keyMoments }: { keyMoments: KeyMoment[] }) => {
  return (
    <SummarySection title="Key moments">
      {keyMoments.map((keyMoment, index) => (
        <KeyMomentsBox {...keyMoment} key={index + keyMoment.name} />
      ))}
    </SummarySection>
  );
};

export default KeyMomentsSection;
