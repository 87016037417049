import { LinkedInDetail } from "@/@types/linkedinDetail";
import { Company } from "@/@types/summary";
import { getIntervieweeSummary } from "@/api/interviewee";
import { GetIntervieweeSummaryResponse } from "@/api/interviewee/types";
import { QUERY_KEYS } from "@/constants/queryKey";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export type useGetSummaryData = Omit<
  GetIntervieweeSummaryResponse,
  "linkedinDetail"
> & {
  linkedinDetail: LinkedInDetail;
};

export const useGetSummary = (id: number | undefined) => {
  return useQuery<useGetSummaryData>({
    queryKey: QUERY_KEYS.INTERVIEW_SUMMARY(id!),
    queryFn: () => getIntervieweeSummary(id!),
    staleTime: 1000 * 60 * 3,
    refetchInterval: (query) => {
      // interviewFile은 있는데 interviewScript가 없으면 30초마다 refetch.
      if (
        query.state.data?.interviewFile &&
        !query.state.data?.interviewScript
      ) {
        return 1000 * 30;
      }

      return 1000 * 60 * 5;
    },
    enabled: !!id,
    retry: 1,
  });
};

export const useGetSummaryRecap = (id: number | undefined) => {
  const queryClient = useQueryClient();

  // return queryClient.getQueryData<GetIntervieweeSummaryResponse>(
  //   QUERY_KEYS.INTERVIEW_SUMMARY(id!)
  // )?.recap;

  return {
    sections: [
      {
        title: "Introduction",
        items: [
          {
            title:
              "Excepteur laborum ipsum sint pariatur culpa veniam do incididunt labore.",
            content:
              "Officia labore non eiusmod nostrud labore eu laboris mollit. Dolore dolor ad duis ex quis ut culpa tempor consectetur. Consectetur id aliqua anim aute proident nulla. Enim excepteur et commodo excepteur laboris veniam excepteur quis. Exercitation sint amet officia fugiat eu culpa minim. Exercitation esse nulla culpa elit fugiat ullamco duis irure.",
          },
          {
            title: "Key Moment 2",
            content:
              "Velit eu magna ad exercitation et magna veniam id mollit reprehenderit. Cupidatat quis labore cupidatat consectetur exercitation incididunt dolor aute ut. Cupidatat eu dolore non ullamco consectetur nostrud ea proident et. Enim reprehenderit qui laborum sint velit enim cupidatat amet.",
          },
        ],
      },
      {
        title: "Key Moments",
        items: [
          {
            title: "Ad velit enim voluptate deserunt est.",
            content:
              "Sint deserunt nulla Lorem dolor exercitation. Ea ad minim elit nostrud do veniam non aute minim laboris et qui. Laboris velit qui in ipsum eiusmod ex ipsum elit nisi incididunt. Quis fugiat ex esse elit mollit ex.",
          },
          {
            title: "Ipsum deserunt voluptate quis est reprehenderit deserunt.",
            content:
              "Incididunt ea consequat adipisicing ullamco ullamco ipsum eiusmod minim qui. Laboris ut eu reprehenderit minim pariatur consequat occaecat deserunt magna. Enim pariatur aliquip pariatur amet ut anim deserunt adipisicing aute. Velit consectetur sunt amet exercitation. Anim dolor irure dolor culpa.",
          },
        ],
      },
      {
        title:
          "Ut ipsum ipsum ipsum enim enim nulla elit laboris proident commodo adipisicing eu Lorem occaecat.",
        items: [
          {
            title: "Ad velit enim voluptate deserunt est.",
            content:
              "Sint deserunt nulla Lorem dolor exercitation. Ea ad minim elit nostrud do veniam non aute minim laboris et qui. Laboris velit qui in ipsum eiusmod ex ipsum elit nisi incididunt. Quis fugiat ex esse elit mollit ex.",
          },
          {
            title: "Ipsum deserunt voluptate quis est reprehenderit deserunt.",
            content:
              "Incididunt ea consequat adipisicing ullamco ullamco ipsum eiusmod minim qui. Laboris ut eu reprehenderit minim pariatur consequat occaecat deserunt magna. Enim pariatur aliquip pariatur amet ut anim deserunt adipisicing aute. Velit consectetur sunt amet exercitation. Anim dolor irure dolor culpa.",
          },
        ],
      },
    ],
  };
};

// company의 경우 미완이기 때문에 그냥 mock data를 return
export const useGetSummaryCompany = (id: number | undefined): Company => {
  // const queryClient = useQueryClient();
  // return queryClient.getQueryData<SummaryData>(
  //   QUERY_KEYS.INTERVIEW_SUMMARY(id!)
  // )?.company;
  return {
    basicInfo: {
      name: "Company Name",
      src: "https://via.placeholder.com/200",
      location: "Company Location",
      description:
        "company description goes here company description goes herecompany description goes herecompany description goes herecompany description goes herecompany description goes herecompany description goes herecompany description goes herecompany description goes herecompany description goes herecompany description goes here",
    },
    basicStats: {
      size: "100-200",
      founded: 1998,
      revenue: "100-200",
      industry: "Technology",
    },
    advancedStats: {
      employeeCount: 100,
      employeeSatisfaction: 4.5,
      revenue: 100,
      growth: 5,
    },
    competitors: [
      {
        name: "Facebook",
        img: "https://via.placeholder.com/200",
        position: "Software Engineer",
      },
      {
        name: "Amazon",
        img: "https://via.placeholder.com/200",
        position: "Software Engineer",
      },
    ],
    people: [
      {
        name: "John Doe",
        position: "Software Engineer",
        img: "https://via.placeholder.com/200",
      },
      {
        name: "John Doe",
        position: "Software Engineer",
        img: "https://via.placeholder.com/200",
      },
      {
        name: "John Doe",
        position: "Software Engineer",
        img: "https://via.placeholder.com/200",
      },
    ],
  };
};

export const useGetSummaryProfileData = (id: number | undefined) => {
  const queryClient = useQueryClient();

  const profile = queryClient.getQueryData<GetIntervieweeSummaryResponse>(
    QUERY_KEYS.INTERVIEW_SUMMARY(id!)
  )?.linkedinDetail;

  if (!profile || typeof profile == "string") return null;

  return profile;
};

export const useGetInterviewQuestions = (id: number | undefined) => {
  const queryClient = useQueryClient();

  return queryClient.getQueryData<GetIntervieweeSummaryResponse>(
    QUERY_KEYS.INTERVIEW_SUMMARY(id!)
  )?.questions;
};
