import Footer from "@/components/Footer";
import Header from "@/components/Header";
import InterviewListSection from "@/components/home/InterviewList/InterviewListSection";
import StartInterviewSection from "@/components/home/StartInterviewSection";
import Container from "@/components/ui/Container";
import useScrollTo from "@/hooks/ui/useScrollTo";

function HomePage() {
  const { scrollTo, targetRef } = useScrollTo();

  return (
    <>
      <Header />
      <Container>
        <StartInterviewSection
          onInterviewCreated={() => {
            scrollTo();
          }}
        />
      </Container>
      <Container>
        <InterviewListSection targetRef={targetRef} />
      </Container>
      <Footer />
    </>
  );
}

export default HomePage;
