// components/SummaryActionBtn.tsx
import { ReactComponent as AiSparkleIcon } from "#/public/icon/ai_sparkle.svg";
import { ReactComponent as UploadIcon } from "#/public/icon/upload.svg";
import { useGetSummaryData } from "@/hooks/summary/useGetSummary";
import React from "react";
import { Button } from "../ui/Button";
import Spinner from "../ui/Spinner";
import Typography from "../ui/Typography";
import { useIsCreateArticleLoading } from "@/hooks/article/useCreateArticle";
import useGetIntervieweeIdParam from "@/hooks/summary/useIntervieweeId";
import useIsUploadScriptLoading from "@/hooks/summary/useIsUploadScriptLoading";

interface SummaryActionBtnProps {
  isFetching: boolean;
  summaryData: useGetSummaryData | undefined;
  onClickGenerateArticle: () => void;
  onClickUploadBtn: () => void;
}

const SummaryActionBtn: React.FC<SummaryActionBtnProps> = ({
  isFetching,
  summaryData,
  onClickGenerateArticle,
  onClickUploadBtn,
}) => {
  const intervieweeId = useGetIntervieweeIdParam();

  const isCreateArticleLoading = useIsCreateArticleLoading({
    intervieweeId: intervieweeId!,
  });

  const isUploadScriptLoading = useIsUploadScriptLoading();

  return isFetching ? (
    <Spinner width="w-6" height="h-6" borderWidth="border-2" />
  ) : summaryData?.interviewScript ? (
    isCreateArticleLoading ? (
      <Button variant={"outline_destructive_fill"} hover={"none"}>
        <Spinner
          width="w-6"
          height="h-6"
          borderWidth="border-2"
          color="border-t-background-red02"
        />
        <div className="mr-3"></div>
        <Typography size={"small1"}>Generating Article...</Typography>
      </Button>
    ) : (
      <Button
        variant={"gradient_background"}
        onClick={onClickGenerateArticle}
        hover={"none"}
      >
        <AiSparkleIcon className="mr-2" />
        <Typography size={"small1"}>Generate Article</Typography>
      </Button>
    )
  ) : summaryData?.interviewFile ? (
    <Button variant={"outline_destructive_fill"} hover={"none"}>
      <Spinner
        width="w-6"
        height="h-6"
        borderWidth="border-2"
        color="border-t-background-red02"
      />
      <div className="mr-3"></div>
      {/* <AiSparkleIcon className="mr-2" /> */}
      <Typography size={"small1"} weight={"semibold"}>
        Generating post summary
      </Typography>
    </Button>
  ) : isUploadScriptLoading ? (
    <Button variant={"secondary"} hover={"none"}>
      <Spinner
        borderWidth="border-2 w-6 h-6"
        color={"border-t-background-blue"}
      />
      <div className="mr-3"></div>
      <Typography size={"small1"}>Uploading Audio...</Typography>
    </Button>
  ) : (
    <Button variant={"secondary"} onClick={onClickUploadBtn}>
      <UploadIcon className="mr-2" />
      <Typography size={"small1"}>Upload Interview Audio</Typography>
    </Button>
  );
};

export default SummaryActionBtn;
