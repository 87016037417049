import FullScreenCenter, {
  FullSizeCenter,
} from "@/components/ui/FullScreenCenter";
import Spinner from "@/components/ui/Spinner";

const LoadingPage = ({
  loadingText = "Loading...",
  isRelative = false,
}: {
  loadingText?: string;
  isRelative?: boolean;
}) => {
  const Comp = isRelative ? FullSizeCenter : FullScreenCenter;
  return (
    <Comp>
      <div className="flex flex-col items-center">
        <Spinner />
        <div className="mb-4"></div>
        {/* <div className="ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4 loader"></div> */}
        <p className="text-xl">{loadingText}</p>
      </div>
    </Comp>
  );
};

export default LoadingPage;
