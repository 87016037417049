import StyledRect, { StyledRectVariants } from "@/components/ui/StyledRect";
import Typography from "@/components/ui/Typography";
import { cn } from "@/utils";
import { VariantProps } from "class-variance-authority";

type styledRectVariantType = VariantProps<typeof StyledRectVariants>["variant"];

const InterviewListInfoBox = ({
  text,
  variant,
  className,
}: {
  text: string | null;
  variant: styledRectVariantType;
  className?: string;
}) => {
  return text ? (
    <StyledRect
      variant={variant}
      size={"sm"}
      rounded={"normal"}
      className={cn("overflow-hidden flex", className)}
    >
      <Typography size={"small2"} weight={"bold"} className="text-wrap w-full">
        {text}
      </Typography>
    </StyledRect>
  ) : null;
};

export default InterviewListInfoBox;
