import { Button } from "@/components/ui/Button";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="space-y-4 text-center">
        <h1 className="text-4xl font-bold">Page Not Found</h1>
        <p className="text-xl">The page you are looking for does not exist.</p>
        <Button onClick={handleClick}>Go to home</Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
