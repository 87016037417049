import React from "react";
import Typography from "../ui/Typography";
import { Button } from "../ui/Button";
import ContentBox from "../ui/ContentBox";
import useFileUploader from "@/hooks/summary/useFileUploader";
import { ReactComponent as MicIcon } from "#/public/icon/mic.svg";
import { ReactComponent as DeleteBtn } from "#/public/icon/delete.svg";
import { ReactComponent as MicGreyLg } from "#/public/icon/mic_lg_gray.svg";
import { Textarea } from "../ui/TextArea";

const UploadedAudioFileItem = ({
  file,
  onClickDeleteBtn,
}: {
  file: File;
  onClickDeleteBtn: () => void;
}) => {
  return (
    <ContentBox
      type={"content_key_moments"}
      padding={"summary_uploaded_file"}
      className="flex items-center gap-2 max-w-[80%] overflow-hidden max-h-[100px]"
    >
      <MicIcon />
      <Typography
        size={"content2"}
        fontColor={"blue"}
        className="overflow-hidden flex-1"
      >
        {file.name}
      </Typography>
      <DeleteBtn
        className="cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          onClickDeleteBtn();
        }}
      ></DeleteBtn>
    </ContentBox>
  );
};

const UploadAudioModal = () => {
  const {
    file,
    textScript,
    isFileScript,
    errorMsg,
    isLoading,
    isDragActive,
    handleChangeToFileMode,
    onClickDeleteBtn,
    onClickGenerateBtn,
    onClickSelectInFinderBtn,
    onChangeTextArea,
    getRootProps,
    getInputProps,
  } = useFileUploader();

  return (
    <div className="w-full h-[90%] bg-background-gray06 border-background-gray05 rounded-veda_border_radius px-12 py-10 flex flex-col">
      <div className="flex-1 flex flex-col items-center">
        <Typography size="content1" weight="semibold" fontColor={"heading"}>
          Upload the script file
        </Typography>
        <div className="mt-2" />
        <Typography size="small1" fontColor={"heading"}>
          You can upload both audio or text file.
        </Typography>
        <div className="mt-8" />
        <div className="flex items-center gap-2.5">
          <Button
            variant={isFileScript ? "outline_black" : "outline_gray"}
            className="w-[160px] h-[48px]"
            hover={"none"}
            rounded={"xl4"}
            onClick={() => handleChangeToFileMode(true)}
          >
            <Typography
              size={"small1"}
              fontColor={"heading"}
              weight={"semibold"}
            >
              Summary
            </Typography>
          </Button>
          <Button
            variant={isFileScript ? "outline_gray" : "outline_black"}
            className="w-[160px] h-[48px]"
            hover={"none"}
            rounded={"xl4"}
            onClick={() => handleChangeToFileMode(false)}
          >
            <Typography
              size={"small1"}
              fontColor={"heading"}
              weight={"semibold"}
            >
              Text
            </Typography>
          </Button>
        </div>
        <div className="mt-4" />
        <ContentBox type={"summary_content"} className="w-[678px] h-[240px]">
          {isFileScript ? (
            <div
              {...getRootProps({ onClick: (e) => e.stopPropagation() })}
              className={`flex items-center justify-center p-6 ${
                isDragActive ? "bg-background-gray04" : ""
              } h-full rounded-veda_border_radius transition-all duration-500
              `}
            >
              <input
                id="fileInput"
                {...getInputProps({
                  accept: ".amr,.flac,.m4a,.mp3,.mp4,.ogg,.webm,.wav",
                })}
              />
              {file ? (
                <UploadedAudioFileItem
                  file={file}
                  onClickDeleteBtn={onClickDeleteBtn}
                />
              ) : (
                <div className="flex flex-col items-center">
                  <MicGreyLg />
                  <Typography
                    size={"content2"}
                    fontColor={"secondary"}
                    weight={"semibold"}
                    className="my-[6px]"
                  >
                    Drag & Drop the audio file
                  </Typography>
                  <Typography
                    size={"content2"}
                    fontColor={"secondary"}
                    weight={"normal"}
                  >
                    (mp3, m4a, wav, flac, webm)
                  </Typography>
                </div>
              )}
            </div>
          ) : (
            <Textarea
              className="w-full h-full leading-snug"
              placeholder="Copy and paste the interview script."
              onChange={onChangeTextArea}
              value={textScript}
            ></Textarea>
          )}
        </ContentBox>
        <div className="mt-8" />
        {isFileScript ? (
          <div onClick={onClickSelectInFinderBtn} className="cursor-pointer">
            <Typography
              size="small1"
              weight="normal"
              fontColor={"blue"}
              className="underline"
            >
              or Search in Finder
            </Typography>
          </div>
        ) : (
          <div className="h-6"></div>
        )}
        <div className="mt-12" />
        <Button
          variant={"secondary"}
          hover={"none"}
          onClick={onClickGenerateBtn}
          isLoading={isLoading}
        >
          <Typography
            size={"content2"}
            fontColor={"primary"}
            weight={"semibold"}
          >
            Upload
          </Typography>
        </Button>
        <Typography size="small1" fontColor={"destructive"} className="mt-4">
          {errorMsg}
        </Typography>
      </div>
    </div>
  );
};

export default UploadAudioModal;
