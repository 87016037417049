import StyledRect from "@/components/ui/StyledRect";
import Typography from "@/components/ui/Typography";
import { cn } from "@/utils";
import React, { memo } from "react";

const LinkSectionContent = memo(
  ({
    number,
    title,
    description,
  }: {
    number: number;
    title: string;
    description?: string;
  }) => {
    return (
      <div className="w-[40%] flex flex-col">
        <StyledRect
          variant="icon_number"
          size="icon_number"
          rounded="full"
          className="flex-shrink-0"
        >
          {number}
        </StyledRect>
        <div className="mt-content_default" />
        <Typography
          size={"heading4"}
          weight={"bold"}
          fontColor={"heading"}
          className="text-shadow-custom whitespace-pre-line"
          lineHeight={"snug"}
        >
          {title}
        </Typography>
        <div className="mt-content_default" />
        <Typography
          size={"content2"}
          weight={"normal"}
          fontColor={"heading"}
          lineHeight={"snug"}
          className="whitespace-pre-line"
        >
          {description}
        </Typography>
      </div>
    );
  }
);

const LinkSection = ({
  title,
  description,
  children,
  number,
  className,
}: {
  title: string;
  description?: string;
  children: React.ReactNode;
  number: number;
  className?: string;
}) => {
  return (
    <div className={cn("flex justify-between", className)}>
      <LinkSectionContent
        number={number}
        title={title}
        description={description}
      />
      {children}
    </div>
  );
};

export default LinkSection;
