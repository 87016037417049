import { create } from "zustand";

interface IntervieweeIdState {
  intervieweeId: number | undefined;
  setIntervieweeId: (id: number | undefined) => void;
  reset: () => void;
}

const initialIntervieweeState: Pick<IntervieweeIdState, "intervieweeId"> = {
  intervieweeId: undefined,
};

const useIntervieweeIdStore = create<IntervieweeIdState>((set) => ({
  ...initialIntervieweeState,
  setIntervieweeId: (id: number | undefined) => {
    set({ intervieweeId: id });
  },
  reset: () => set(initialIntervieweeState),
}));

export default useIntervieweeIdStore;
