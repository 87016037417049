import ContentBox from "@/components/ui/ContentBox";
import ProfileImg from "@/components/ui/ProfileImg";
import TypographyWithHoverModal from "@/components/ui/Typography/TypographyWithHoverModal";

const KeyMomentsSourceBox = ({
  sourceImg,
  sourceText,
  sourceLink,
}: {
  sourceImg: string | null;
  sourceText: string;
  sourceLink: string;
}) => {
  return (
    <ContentBox
      type={"content_key_moments"}
      className="w-[320px] h-[96px] flex cursor-pointer gap-6"
      href={sourceLink}
      href_target="_blank"
      padding={"key_moments"}
    >
      <ProfileImg width="w-16" height="h-16" img={sourceImg} />
      <TypographyWithHoverModal
        size={"small1"}
        fontColor={"tertiary"}
        className="text-wrap text-ellipsis h-16"
        modalClassName="w-[320px] right-0"
        lineHeight={"snug"}
        weight={"semibold"}
        modalText={sourceText}
      >
        {sourceText}
      </TypographyWithHoverModal>
    </ContentBox>
  );
};

export default KeyMomentsSourceBox;
