import { cn } from "@/utils";
import { cva, VariantProps } from "class-variance-authority";
import React, { forwardRef } from "react";

export const StyledRectVariants = cva(
  // "inline-flex items-center justify-center whitespace-nowrap rounded-text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:bg-zinc-600",
  "box-border flex items-center justify-center whitespace-nowrap rounded-veda_border_radius text-md font-medium leading-none",
  {
    variants: {
      variant: {
        muted: "border border-[1px] border-muted-border bg-muted",
        secondary: "font-semibold bg-background-blue",
        icon_number: "bg-background-green01 text-background-black font-bold",
        tertiary:
          "bg-tertiary text-tertiary-foreground border-1px border-tertiary-foreground",
        outline:
          "border border-[1px] border-primary bg-background text-background-green01",
        white:
          "bg-background-gray01 text-background-black border-bg-background-gray02 border-[2px]",
      },
      size: {
        sm: "rounded-md px-[12px] py-[8px] text-sm",
        icon_number: "h-[28px] w-[28px]",
      },
      rounded: {
        normal: "rounded-vexa_border_radius",
        xl: "rounded-veda_border_radius_xl",
        full: "rounded-full",
      },
    },
  }
);

interface StyledRectProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof StyledRectVariants> {
  children?: React.ReactNode;
  className?: string;
}

// const StyledRect = ({
//   children,
//   className,
//   size,
//   variant,
//   rounded,
//   ...props
// }: StyledRectProps) => {
//   return (
//     <div
//       className={cn(StyledRectVariants({ variant, size, rounded, className }))}
//       {...props}
//     >
//       {children}
//     </div>
//   );
// };

const StyledRect = forwardRef<HTMLDivElement, StyledRectProps>(
  ({ children, className, size, variant, rounded, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          StyledRectVariants({ variant, size, rounded, className })
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export default StyledRect;
