import { createArticle } from "@/api/article";
import { CreateArticleRequest } from "@/api/article/types";
import { QUERY_KEYS } from "@/constants/queryKey";
import {
  useIsMutating,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import useToast from "../ui/useToast";
import { MUTATION_KEYS } from "@/constants/mutationKey";
import useIntervieweeIdParam from "../useIntervieweeIdParam";

export const useCreateArticle = () => {
  const queryClient = useQueryClient();

  const { showToast } = useToast();

  const intervieweeId = useIntervieweeIdParam();

  return useMutation({
    mutationFn: (body: CreateArticleRequest) => createArticle(body),
    mutationKey: MUTATION_KEYS.CREATE_ARTICLE(intervieweeId!),
    onSuccess: () => {
      if (intervieweeId) {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.ARTICLES(intervieweeId),
        });
      }

      showToast({
        message: "아티클이 성공적으로 생성되었습니다.",
        type: "success",
      });
    },
    onError: () => {
      showToast({
        message: "아티클 생성에 실패했습니다. 다시 시도해주세요.",
        type: "error",
      });
    },
  });
};

export const useIsCreateArticleLoading = ({
  intervieweeId,
}: {
  intervieweeId: number;
}) => {
  const creatingArticle = useIsMutating({
    mutationKey: MUTATION_KEYS.CREATE_ARTICLE(intervieweeId),
  });

  return creatingArticle > 0;
};
