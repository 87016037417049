import ProfileImg from "../ui/ProfileImg";
import Typography from "../ui/Typography";

export interface ExperienceItemProps {
  main: string | null;
  sub: string | null;
  description: string | null;
  period: string | null;
  img_url: string | null;
}

const ExperienceItem = ({
  main,
  sub,
  description,
  period,
  img_url,
}: ExperienceItemProps) => {
  return (
    <div className="">
      <div className="flex gap-5">
        <ProfileImg width="w-[64px]" height="h-[64px]" img={img_url} />
        <div className="flex-1 flex flex-col justify-center overflow-hidden">
          <Typography
            size={"content2"}
            fontColor={"heading"}
            lineHeight={"snug"}
            className="overflow-hidden text-ellipsis"
          >
            {main}
          </Typography>
          <Typography
            size={"small3"}
            fontColor={"heading"}
            weight={"normal"}
            lineHeight={"snug"}
          >
            {sub}
          </Typography>
          <Typography
            size={"small3"}
            fontColor={"secondary"}
            lineHeight={"snug"}
          >
            {period}
          </Typography>
        </div>
      </div>
      <div className="mt-content_default" />
      <Typography
        size={"small2"}
        fontColor={"primary"}
        lineHeight={"snug"}
        weight={"normal"}
      >
        {description}
      </Typography>
    </div>
  );
};

export default ExperienceItem;
