export const PAGE_PATH_ALIAS = {
  HOME: "HOME" as const,
  TEST: "TEST" as const,
  LOGIN: "LOGIN" as const,
  REGISTER: "REGISTER" as const,
  SUMMARY: "SUMMARY" as const,
  QUESTIONS: "QUESTIONS" as const,
  LIST: "LIST" as const,
  CREATE: "CREATE" as const,
};
