import { getPromptsForArticle } from "@/api/article";
import { QUERY_KEYS } from "@/constants/queryKey";
import { useQuery } from "@tanstack/react-query";

export const useGetPromptsForArticle = () => {
  return useQuery({
    queryKey: QUERY_KEYS.ARTICLE_PROMPTS, // intervieweeId를 queryKey로 사용
    retry: false,
    staleTime: 1000 * 60 * 60, // 1시간동안 캐시된 데이터 사용
    queryFn: getPromptsForArticle, // getArticlesByInterviewee에 intervieweeId 전달
  });
};
