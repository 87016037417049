import { cn } from "@/utils";
import ContentBox from "../ui/ContentBox";
import Typography from "../ui/Typography";

export const ArticleTopicButton = ({
  topic,
  subText = "Focus on",
  isSelected,
  onClick,
}: {
  onClick: () => void;
  topic: string;
  subText?: string;
  isSelected: boolean;
}) => {
  const fontColor = isSelected ? "primary" : "green_secondary";

  return (
    <ContentBox
      type={
        isSelected ? "content_article_topic_selected" : "content_article_topic"
      }
      className={cn("flex-1 cursor-pointer", {
        "hover:opacity-100": !isSelected,
        "opacity-70": !isSelected,
      })}
      padding={"article_topic_btn"}
      onClick={onClick}
    >
      <div className="flex flex-col gap-2">
        <Typography size={"small1"} fontColor={fontColor} weight={"normal"}>
          {subText}
        </Typography>
        <Typography size={"content1"} fontColor={fontColor} weight={"semibold"}>
          {topic}
        </Typography>
      </div>
    </ContentBox>
  );
};

// opacity-[0.7] hover:opacity-100
