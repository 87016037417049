export const PAGE_ROUTES = {
  HOME: "/",
  TEST: "/test",
  LOGIN: "/login",
  REGISTER: "/register",
  INTERVIEW: {
    SUMMARY: (interviewId: string | number) => `/interview/${interviewId}`,
    QUESTIONS: (interviewId: string | number) =>
      `/interview/${interviewId}/questions`,
  },
  ARTICLE: {
    LIST: (intervieweeId: string | number) => `/article/${intervieweeId}`,
    CREATE: (intervieweeId: string | number) =>
      `/article/${intervieweeId}/create`,
  },
};

export const API_ROUTES = {
  AUTH: {
    LOGIN: "/auth/login",
    SIGNUP: "/auth/signup",
    CHECK: "/auth/check",
  },
  INTERVIEWEE: {
    GET_LINKEDIN_PROFILE: (linkedin: string) =>
      `/interviewee/linkedin?linkedin_url=${linkedin}`,
    CREATE_INTERVIEWEE: "/interviewee",
    GET_INTERVIEWEES: "/interviewee",
    GET_INTERVIEWEE_SUMMARY: (id: number) => `/interviewee/${id}`,
    CREATE_QUESTION: (id: number) => `/interviewee/${id}/question/`,
    DELETE_INTERVIEWEE: (id: number) => `/interviewee/${id}`,
    PATCH_DETAIL: (id: number) => `/interviewee/${id}`,
    GET_PRESIGNED_URL: (intervieweeId: number) =>
      `/interviewee/${intervieweeId}/interview_file_url/`,
  },
  ARTICLE: {
    CREATE: "/article", // 아티클 생성
    GET_ALL: "/article", // 아티클 목록 조회
    GET_DETAIL: (articleId: number) => `/article/${articleId}`, // 아티클 상세 조회
    DELETE: (articleId: number) => `/article/${articleId}`, // 아티클 삭제
    GET_ARTICLES_BY_INTERVIEWEE: (intervieweeId: number) =>
      `/interviewee/${intervieweeId}/articles`,
    GET_PROMPTS: "/prompts/",
  },
};
