export function isValidUrl(str: string) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?(www\\.)?" + // protocol or www (optional)
      "([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,}" + // domain name and TLD (no underscore, TLD with 2 or more letters)
      "(\\/[^?]*)?" + // optional path (no query string)
      "(\\?[^\\s]*)?$", // optional query string (ending with ? and followed by parameters)
    "i"
  );
  return pattern.test(str);
}
