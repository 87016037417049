import React from "react";
import ContentBox from "../ContentBox";
import Typography from "../Typography";
import { cn } from "@/utils";

export const TypoModal = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("absolute z-50", className)}>
      <ContentBox type={"white"}>
        <Typography
          size={"small2"}
          fontColor={"primary_reverse"}
          lineHeight={"snug"}
          weight={"medium"}
        >
          {children}
        </Typography>
      </ContentBox>
    </div>
  );
};
