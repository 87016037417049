import ContentBox, { ContentBoxVariants } from "@/components/ui/ContentBox";
import Divider from "@/components/ui/Divider";
import ProfileImg from "@/components/ui/ProfileImg";
import StyledRect, { StyledRectVariants } from "@/components/ui/StyledRect";
import { PAGE_ROUTES } from "@/constants/routeName";
import useToast from "@/hooks/ui/useToast";
import { cn } from "@/utils";
import { formatISOStringToCustomDate } from "@/utils/format/formatDate";
import { VariantProps } from "class-variance-authority";
import { memo } from "react";
import InterviewListInfoBox from "./InterviewListInfoBox";
import ItemTextSection from "./ItemTextSection";
import { useGetIntervieweesItem } from "@/hooks/home/useGetInterviewees";
import { IntervieweeType } from "@/@types/interviewee";
import Typography from "@/components/ui/Typography";

type contentBoxVariantType = VariantProps<typeof ContentBoxVariants>["type"];
type styledRectVariantType = VariantProps<typeof StyledRectVariants>["variant"];

const ListItemType: {
  [key in IntervieweeType]: {
    contentBoxVariant: contentBoxVariantType;
    itemDividerColor: string;
    styledRectVariant: styledRectVariantType;
    backgroundColor?: string;
  };
} = {
  creating: {
    contentBoxVariant: "item_destructive_not_created",
    itemDividerColor: "bg-background-gray03",
    styledRectVariant: "muted",
    backgroundColor: "bg-background-red02",
  },
  created_not_audio_uploaded: {
    contentBoxVariant: "item_destructive",
    itemDividerColor: "bg-background-gray03",
    styledRectVariant: "outline",
    backgroundColor: "bg-background-gray05",
  },
  created_audio_uploaded: {
    contentBoxVariant: "item_normal",
    itemDividerColor: "bg-background-gray05",
    styledRectVariant: "outline",
    backgroundColor: "bg-background-gray06",
  },
  created_youtube_uploaded: {
    contentBoxVariant: "item_complete",
    itemDividerColor: "bg-background-gray05",
    styledRectVariant: "outline",
    backgroundColor: "bg-background-green03",
  },
};

const defaultValues = {
  contentBoxVariant: "item_default",
  itemDividerColor: "bg-background-default",
  styledRectVariant: "outline",
  backgroundColor: "bg-background-default",
};

const InterviewListItem = ({
  type,
  id,
  createdAt,
  linkedinDetail,
  name,
  updatedAt,
  userId,
}: useGetIntervieweesItem) => {
  const {
    contentBoxVariant,
    itemDividerColor,
    styledRectVariant,
    backgroundColor,
  } = ListItemType[type] || defaultValues;

  const { showToast } = useToast();

  const formattedCreatedAt = formatISOStringToCustomDate(createdAt);
  const formattedUpdatedAt = updatedAt
    ? formatISOStringToCustomDate(updatedAt)
    : "-";

  const href =
    type !== "creating" ? PAGE_ROUTES.INTERVIEW.SUMMARY(id) : undefined;

  const onClickContentBox = () => {
    if (!href) {
      showToast({
        message: "인터뷰 정보를 생성하는 중입니다.",
        type: "error",
      });
    }
  };

  const previousCompany = linkedinDetail?.experiences?.sort((a, b) => {
    if (a.starts_at?.year && b.starts_at?.year) {
      return b.starts_at?.year - a.starts_at?.year;
    }
    return 0;
  })?.[0];

  const previousCompanyPeriod = previousCompany?.ends_at
    ? `${previousCompany?.starts_at?.year} - ${previousCompany?.ends_at.year}`
    : `current`;

  return (
    <ContentBox
      href={href}
      type={contentBoxVariant}
      onClick={onClickContentBox}
      className={cn(
        "flex cursor-pointer hover:opacity-[0.7] transition-all overflow-hidden",
        backgroundColor
      )}
    >
      <div className="flex gap-default_gap w-1/4">
        <div className="w-24 h-24 bg-background-white rounded-full flex-shrink-0">
          <ProfileImg
            img={linkedinDetail.profile_pic_url}
            width="w-24"
            height="h-24"
          />
        </div>
        <div className="flex-1 overflow-hidden flex flex-col gap-2 justify-center">
          <Typography size={"content2"} weight={"bold"} fontColor={"primary"}>
            {name}
          </Typography>
          <div className="flex gap-1 items-start">
            <InterviewListInfoBox
              variant={styledRectVariant}
              text={linkedinDetail.occupation}
              className="max-w-full"
            ></InterviewListInfoBox>
          </div>
        </div>
      </div>
      <ItemDivider color={itemDividerColor} />
      <div className="flex gap-default_gap w-1/4">
        {previousCompany ? (
          <>
            <StyledRect
              variant={"muted"}
              size={"sm"}
              rounded={"xl"}
              className="w-24 h-24 flex-shrink-0 p-0 overflow-hidden"
            >
              <ProfileImg
                img={previousCompany.logo_url}
                width="w-24"
                height="h-24"
                rounded={false}
              />
            </StyledRect>
            <div className="flex-1 overflow-hidden flex flex-col gap-2 justify-center">
              <Typography
                size={"content2"}
                weight={"bold"}
                fontColor={"primary"}
              >
                {previousCompany.company}
              </Typography>
              <div className="flex gap-1 items-start">
                <InterviewListInfoBox
                  variant={"tertiary"}
                  // text={previousCompany.title}
                  text={"-"}
                  className="max-w-half min-w-12 text-center"
                />
                <InterviewListInfoBox
                  variant={"tertiary"}
                  // text={previousCompanyPeriod}
                  text={"-"}
                  className="max-w-half min-w-12 text-center"
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
      <ItemDivider color={itemDividerColor} />
      {/* <ItemTextSection
        title={"-"}
        subtitle={"Interviewed by"}
      ></ItemTextSection> */}
      {/* <ItemDivider color={itemDividerColor} /> */}
      <ItemTextSection
        title={formattedCreatedAt}
        // subtitle={"Interviewed on"}
        subtitle="Created on"
      ></ItemTextSection>
      <ItemDivider color={itemDividerColor} />
      <ItemTextSection
        title={formattedUpdatedAt}
        // subtitle={"Published on"}
        subtitle="Updated on"
      ></ItemTextSection>
      <ItemDivider color={itemDividerColor} />
    </ContentBox>
  );
};

export default memo(InterviewListItem);

const ItemDivider = ({ color }: { color?: string }) => {
  return (
    <div className="mx-[31px] h-auto flex justify-center items-center">
      <Divider direction={"vertical"} className={cn(`h-[70%]`, color)} />
    </div>
  );
};
