import React from "react";
import ContentBox from "../ui/ContentBox";
import Typography from "../ui/Typography";

const ContentBoxForSummary = ({
  title,
  children,
  classNameForContentBox,
}: {
  children: React.ReactNode;
  title: string;
  classNameForContentBox?: string;
}) => {
  return (
    <ContentBox
      padding={"p6"}
      className={classNameForContentBox}
      type={"summary_content"}
    >
      <Typography size={"content2"} fontColor={"tertiary"}>
        {title}
      </Typography>
      <div className="mt-6"></div>
      {children}
    </ContentBox>
  );
};

export default ContentBoxForSummary;
