export function isLinkedInProfileUrl(url: string): boolean {
  const linkedInProfilePattern =
    /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[\w%\-\/]+(\?[^\s]*)?$/;
  return linkedInProfilePattern.test(url);
}

export function linkedInProfileUrlValidator(url: string): string {
  if (url.trim() === "") {
    return "링크드인 주소를 입력한 후 Enter버튼을 눌러주세요.";
  }

  if (!isLinkedInProfileUrl(url.trim())) {
    return "올바른 링크드인 주소를 입력해주세요.";
  }

  return "";
}
