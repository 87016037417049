// Router.tsx
import React from "react";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./components/routeUtils";
import { PAGE_ROUTES } from "./constants/routeName";
import NotFoundPage from "./pages/@common/NotFoundPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/auth/LoginPage";
import RegisterPage from "./pages/auth/RegisterPage";
import SummaryPage from "./pages/interview/SummaryPage";
import QuestionPage from "./pages/interview/QuestionPage";
import ArticleListPage from "./pages/article/ArticleListPage";
import ArticleCreatePage from "./pages/article/ArticleCreatePage";

const Router: React.FC = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path={PAGE_ROUTES.HOME} element={<HomePage />} />
        <Route
          path={PAGE_ROUTES.INTERVIEW.SUMMARY(":interviewId")}
          element={<SummaryPage />}
        />
        <Route
          path={PAGE_ROUTES.INTERVIEW.QUESTIONS(":interviewId")}
          element={<QuestionPage />}
        />
        <Route
          path={PAGE_ROUTES.ARTICLE.LIST(":interviewId")}
          element={<ArticleListPage />}
        />
        <Route
          path={PAGE_ROUTES.ARTICLE.CREATE(":interviewId")}
          element={<ArticleCreatePage />}
        />
      </Route>
      <Route element={<PublicRoute />}>
        <Route path={PAGE_ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={PAGE_ROUTES.REGISTER} element={<RegisterPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
