import Footer from "@/components/Footer";
import Header from "@/components/Header";
import FixedNavBtn from "@/components/summary/FixedNavBtn";
import ContentBox from "@/components/ui/ContentBox";
import { PageLayout } from "@/components/ui/Layout";
import { QuestionSection } from "@/components/ui/Section";
import TypographyWithFootNote from "@/components/ui/Typography/TypographyWithFootNote";
import { PAGE_ROUTES } from "@/constants/routeName";
import { useQuestions } from "@/hooks/summary/useQuestions";
import useScrollTopOnMount from "@/hooks/ui/useScrollTopOnMount";
import useIntervieweeIdParam from "@/hooks/useIntervieweeIdParam";
import { mergeQuestionsByTheme } from "@/utils/format/mergeQuestionsByTheme";
import { useNavigate } from "react-router-dom";
import ErrorPage from "../@common/ErrorPage";
import LoadingPage from "../@common/LoadingPage";

const QuestionPage = () => {
  useScrollTopOnMount();

  const navigate = useNavigate();

  const id = useIntervieweeIdParam();

  const {
    questions,
    intervieweeName,
    isLoading: isQuestionLoading,
    isError: isQuestionError,
    refetch: refetchQuestions,
    isCreateQuestionError,
    isCreateQuestionPending,
    createQuestion,
  } = useQuestions(id);

  if (!id) {
    return (
      <ErrorPage
        error={{
          message: "잘못된 접근입니다.",
        }}
        buttonLabel={"Go back to home"}
        onRetry={() => navigate(PAGE_ROUTES.HOME)}
      />
    );
  }

  if (isQuestionLoading) {
    return <LoadingPage></LoadingPage>;
  }

  if (isQuestionError || !questions) {
    return (
      <ErrorPage
        onRetry={() => {
          if (!id) navigate(PAGE_ROUTES.HOME);
          else refetchQuestions();
        }}
        error={{
          message: id
            ? "질문을 불러오는데 실패했습니다."
            : "잘못된 접근입니다.",
        }}
        buttonLabel={!id ? "Go back to home" : "Retry"}
      ></ErrorPage>
    );
  }

  if (!isCreateQuestionPending && isCreateQuestionError) {
    return (
      <>
        <ErrorPage
          onRetry={() => createQuestion(id)}
          error={{
            message: "질문을 생성하는데 실패했습니다. 다시 시도해주세요.",
          }}
        ></ErrorPage>
        <FixedNavBtn></FixedNavBtn>
      </>
    );
  }

  if (questions.length === 0) {
    return (
      <LoadingPage loadingText="질문을 생성하는 중입니다..."></LoadingPage>
    );
  }

  const questionThemes = mergeQuestionsByTheme(questions);

  const onClickArrow = () => {
    navigate(PAGE_ROUTES.INTERVIEW.SUMMARY(id));
  };

  return (
    <>
      <Header></Header>
      <PageLayout
        title={
          <PageLayout.Title onClickArrow={onClickArrow}>
            <div className="flex-1 flex justify-between items-center">
              <div className="flex items-center gap-3">
                <PageLayout.TitleText>Questions for </PageLayout.TitleText>
                <PageLayout.TitleText fontColor={"green_primary"}>
                  {intervieweeName}
                </PageLayout.TitleText>
              </div>
            </div>
          </PageLayout.Title>
        }
      >
        {questionThemes.map((theme, index) => (
          <QuestionSection key={index + theme.name} title={theme.name}>
            <ContentBox type="content_interview" className="relative">
              {theme.questions.map((question, index) => (
                <TypographyWithFootNote
                  key={index}
                  size={"content1"}
                  weight={"semibold"}
                  className="whitespace-pre-wrap"
                  footNote={question.source}
                >
                  {question.description}
                </TypographyWithFootNote>
              ))}
            </ContentBox>
          </QuestionSection>
        ))}
      </PageLayout>
      <FixedNavBtn></FixedNavBtn>
      <Footer></Footer>
    </>
  );
};

export default QuestionPage;
