import useGetIntervieweeIdParam from "@/hooks/summary/useIntervieweeId";
import { useGetSummaryProfileData } from "@/hooks/summary/useGetSummary";
import formatStartEndDate from "@/utils/format/formatStartEndDate";
import ContentBoxForSummary from "../ContentBoxForSummary";
import ExperienceItem, { ExperienceItemProps } from "../ExperienceItem";

const EducationSection = () => {
  const id = useGetIntervieweeIdParam();
  const linkedInDetail = useGetSummaryProfileData(id);

  if (!linkedInDetail) return null;

  const EducationData: ExperienceItemProps[] | undefined =
    linkedInDetail.education?.map((item) => ({
      main: item.school,
      sub: item.degree_name,
      period: formatStartEndDate({
        starts_at: item.starts_at,
        ends_at: item.ends_at,
      }),
      img_url: item.logo_url,
      description: item.description,
    }));

  return (
    <ContentBoxForSummary title="Career">
      <div className="flex flex-col gap-summary_experience_item_gap">
        {EducationData?.map((data, index) => {
          return <ExperienceItem key={index} {...data} />;
        })}
      </div>
    </ContentBoxForSummary>
  );
};

export default EducationSection;
