import useModalStore from "@/store/modalStore";

const useModal = () => {
  const { closeModal, openModal } = useModalStore();

  return {
    openModal,
    closeModal,
  };
};

export default useModal;
