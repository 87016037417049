import { createIntervieweeQuestion } from "@/api/interviewee";
import { QUERY_KEYS } from "@/constants/queryKey";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useGetSummaryData } from "./useGetSummary";
import useToast from "../ui/useToast";

export const useCreateIntervieweeQuestion = () => {
  const queryClient = useQueryClient();

  const { showToast } = useToast();

  return useMutation({
    mutationFn: (id: number | undefined) => {
      return createIntervieweeQuestion(id!);
    },
    onSuccess: (data, id) => {
      queryClient.setQueryData<useGetSummaryData>(
        QUERY_KEYS.INTERVIEW_SUMMARY(id!),
        (prev) => {
          if (!prev) return;

          return {
            ...prev,
            questions: data,
          };
        }
      );
    },
    onError: (error) => {
      showToast({
        message: "인터뷰 질문을 생성하는데 실패했습니다. 다시 시도해주세요.",
        type: "error",
      });
    },
  });
};
