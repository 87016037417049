// hooks/useLinkForm.ts
import { useState } from "react";
import { isValidUrl } from "@/utils/validation/isValidUrl";
import useToast from "../ui/useToast";
import { linkedInProfileUrlValidator } from "@/utils/validation/isLinkedInProfileUrl";
import useLinkFormStore from "@/store/linkFormStore";

const useLinkForm = () => {
  const {
    linkForm,
    setLinkedIn,
    addReference,
    removeReference,
    resetLinkForm,
    resetAll,
  } = useLinkFormStore();

  const [newReference, setNewReference] = useState<string>("");
  const { showToast } = useToast();

  const handleChangeLinkedInForm = (value: string) => {
    setLinkedIn(value);
  };

  const handleAddReference = () => {
    if (newReference.trim() !== "") {
      if (!isValidUrl(newReference.trim())) {
        showToast({
          type: "error",
          message: "URL 형식이 올바르지 않습니다.",
        });
        return;
      }
      addReference(newReference);
      setNewReference("");
    }
  };

  const handleSubmitReference = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAddReference();
  };

  const handleRemoveReference = (index: number) => {
    removeReference(index);
  };

  const isCreateBtnDisabled = !linkForm.linkedin || !linkForm.references.length;

  const validateLinkForm = () => {
    const linkedInErrorMsg = linkedInProfileUrlValidator(linkForm.linkedin);

    if (linkedInErrorMsg) {
      return linkedInErrorMsg;
    }

    if (linkForm.references.length === 0) {
      return "하나 이상의 Reference Link를 추가해주세요.";
    }

    return null;
  };

  const createButtonErrorMsg = validateLinkForm();

  return {
    linkForm,
    newReference,
    isCreateBtnDisabled,
    createButtonErrorMsg,
    handleChangeLinkedInForm,
    setNewReference,
    handleAddReference,
    handleSubmitReference,
    handleRemoveReference,
    removeReference,
    resetLinkForm,
    resetAll,
    validateLinkForm,
  };
};

export default useLinkForm;
