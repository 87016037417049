import { getPromptsForArticleResponse } from "@/api/article/types";
import { articlePromptMap } from "@/constants/promptsMap";
import { ArticleTopicButton } from "./ArticleTopicButton";

const ArticleTopicSelect = ({
  prompts,
  selectedTopicIndex,
  onClickPromptBox,
}: {
  prompts: getPromptsForArticleResponse | undefined;
  onClickPromptBox: (index: number) => void;
  selectedTopicIndex: number | null;
}) => {
  return (
    <div className="flex gap-default_gap">
      {prompts
        ?.sort((promptA, promptB) => {
          const orderA =
            articlePromptMap[promptA.name as keyof typeof articlePromptMap]
              ?.order || 0;
          const orderB =
            articlePromptMap[promptB.name as keyof typeof articlePromptMap]
              ?.order || 0;

          return orderA - orderB;
        })
        .map((prompt, index) => {
          const isSelcted = selectedTopicIndex == index;
          const onClick = () => onClickPromptBox(index);

          const subText =
            articlePromptMap[prompt.name as keyof typeof articlePromptMap]
              ?.subText || "";

          const topic =
            articlePromptMap[prompt.name as keyof typeof articlePromptMap]
              ?.topic || "";

          return (
            <ArticleTopicButton
              onClick={onClick}
              key={index + prompt.name}
              topic={topic}
              subText={subText}
              isSelected={isSelcted}
            />
          );
        })}
    </div>
  );
};

export default ArticleTopicSelect;
