import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/utils"; // 유틸리티 함수 가져오기

// Tailwind CSS로 스타일 변형 정의
const typographyVariants = cva("leading-none", {
  variants: {
    size: {
      heading: "text-[48px]",
      heading2: "text-[40px]",
      heading3: "text-[36px]",
      heading4: "text-[32px]",
      content1: "text-[24px]",
      content2: "text-[20px]",
      small1: "text-[16px]",
      small2: "text-[14px]",
      small3: "text-[12px]",
    },
    weight: {
      thin: "font-thin",
      light: "font-light",
      normal: "font-normal",
      medium: "font-medium",
      semibold: "font-semibold",
      bold: "font-bold",
      extrabold: "font-extrabold",
    },

    fontColor: {
      primary: "text-background-white",
      primary_reverse: "text-background-black",
      muted: "text-background-gray05",
      secondary: "text-background-gray03",
      tertiary: "text-background-gray02",
      heading: "text-background-gray01",
      green_primary: "text-background-green01",
      green_secondary: "text-background-green02",
      destructive: "text-background-red",
      disabled: "text-background-gray04",
      blue: "text-background-blue",
    },
    lineHeight: {
      none: "leading-none",
      normal: "leading-normal",
      snug: "leading-snug",
      loose: "leading-loose",
    },
    letterSpacing: {
      normal: "tracking-normal",
      wide: "tracking-wide",
      tight: "tracking-tight",
    },
    fontFamily: {
      default: "",
      lexend: "font-lexend",
    },
  },
  defaultVariants: {
    weight: "medium",
    lineHeight: "none",
    letterSpacing: "tight",
    fontFamily: "default",
  },
});

export interface TypographyProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof typographyVariants> {}

const Typography: React.FC<TypographyProps> = ({
  className,
  size,
  weight,
  fontColor,
  style,
  lineHeight,
  letterSpacing,
  fontFamily,
  ...props
}) => {
  const mergedClassName = cn(
    typographyVariants({
      size,
      weight,
      fontColor,
      lineHeight,
      letterSpacing,
      fontFamily,
    }),
    className
  );

  return <BasicTypography className={mergedClassName} {...props} />;
};

const BasicTypography: React.FC<TypographyProps> = ({
  className,
  style,
  ...props
}) => {
  return (
    <span className={className} style={style} {...props}>
      {props.children}
    </span>
  );
};

export default Typography;
