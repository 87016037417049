import Typography from "@/components/ui/Typography";
import { ReactComponent as DeleteIcon } from "#/public/icon/delete.svg";
import React from "react";

const LinkUrlItem = ({
  text,
  onClickDelete,
}: {
  text: string;
  onClickDelete: () => void;
}) => {
  return (
    <div className="flex items-center gap-3 bg-tertiary text-tertiary-foreground py-3 px-4 rounded-veda_border_radius max-w-[58%] ">
      <Typography
        className="overflow-hidden text-ellipsis text-nowrap"
        size={"content2"}
      >
        {text}
      </Typography>
      <DeleteIcon
        role="button"
        aria-label="delete_reference"
        className="cursor-pointer hover:opacity-[0.8] transition-all flex-shrink-0"
        onClick={onClickDelete}
      ></DeleteIcon>
    </div>
  );
};

export default LinkUrlItem;
