import { patchIntervieweeDetail } from "@/api/interviewee";
import {
  PatchIntervieweeDetailRequest,
  PatchIntervieweeDetailResponse,
} from "@/api/interviewee/types";
import { useMutation } from "@tanstack/react-query";
import useIntervieweeIdParam from "../useIntervieweeIdParam";
import { MUTATION_KEYS } from "@/constants/mutationKey";
import useGetIntervieweeId from "./useIntervieweeId";

const usePatchIntervieweeDetail = ({
  onSuccess,
  onError,
}: {
  onSuccess?:
    | ((
        data: PatchIntervieweeDetailResponse,
        variables: {
          intervieweeId: number | undefined;
          body: Partial<PatchIntervieweeDetailRequest>;
        },
        context: unknown
      ) => Promise<unknown> | unknown)
    | undefined;
  onError?: (error: Error) => void;
}) => {
  const intervieweeId = useGetIntervieweeId();

  return useMutation<
    PatchIntervieweeDetailResponse,
    Error,
    {
      intervieweeId: number | undefined;
      body: Partial<PatchIntervieweeDetailRequest>;
    }
  >({
    mutationKey: MUTATION_KEYS.PATCH_USER_SUMMARY(intervieweeId!),
    mutationFn: ({ intervieweeId, body }) => {
      if (!intervieweeId) {
        return Promise.reject(new Error("Interviewee ID is required."));
      }

      return patchIntervieweeDetail(intervieweeId, body);
    },
    onSuccess,
    onError,
  });
};

export default usePatchIntervieweeDetail;
