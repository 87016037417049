import ContentBox from "../../ui/ContentBox";
import CareerSection from "./CareerSection";
import EducationSection from "./EducationSection";
import PeopleSectionProfile from "./PeopleSectionProfile";

const PeopleSection = () => {
  // const { openModal } = useModalStore();

  // const onClickModalBtn = () => {
  //   openModal({
  //     component: <BasicInformationModal />,
  //   });
  // };
  return (
    <ContentBox
      padding={"summary_content_container"}
      className="flex-1 flex flex-col gap-summary_profile_section_gap overflow-auto"
    >
      <PeopleSectionProfile />
      <CareerSection />
      <EducationSection />
    </ContentBox>
  );
};

export default PeopleSection;
