// api/article.ts
import { API_ROUTES } from "@/constants/routeName";
import { api } from "@/lib/axios";
import {
  CreateArticleRequest,
  CreateArticleResponse,
  GetArticleDetailResponse,
  GetArticlesResponse,
  getPromptsForArticleResponse,
} from "./types";

// 특정 인터뷰이의 Article 목록을 가져오는 함수

// 아티클 생성
export const createArticle = async (body: CreateArticleRequest) => {
  return await api.post<CreateArticleResponse>(API_ROUTES.ARTICLE.CREATE, body);
};

// 아티클 목록 조회
export const getArticles = async (params: {
  interviewee_id?: number;
  user_id?: number;
}) => {
  return await api.get<GetArticlesResponse>(API_ROUTES.ARTICLE.GET_ALL, params);
};

// 아티클 상세 조회
export const getArticleDetail = async (article_id: number) => {
  return await api.get<GetArticleDetailResponse>(
    API_ROUTES.ARTICLE.GET_DETAIL(article_id)
  );
};

// 아티클 삭제
export const deleteArticle = async (article_id: number) => {
  return await api.delete<void>(API_ROUTES.ARTICLE.DELETE(article_id));
};

export const getPromptsForArticle = async () => {
  return await api.get<getPromptsForArticleResponse>(
    API_ROUTES.ARTICLE.GET_PROMPTS
  );
};
