import { useRef } from "react";

const useScrollTo = () => {
  const targetRef = useRef<HTMLDivElement>(null);

  const scrollTo = (align: ScrollLogicalPosition = "start") => {
    targetRef.current?.scrollIntoView({ behavior: "smooth", block: align });
  };

  return { targetRef, scrollTo };
};

export default useScrollTo;
