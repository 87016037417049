import { PAGE_PATH_ALIAS } from "@/constants/pagePathAlias";
import { PAGE_ROUTES } from "@/constants/routeName";
import { useGetSummary } from "@/hooks/summary/useGetSummary";
import useIntervieweeIdParam from "@/hooks/useIntervieweeIdParam";
import usePagePath from "@/hooks/usePagePath";
import { Button } from "../ui/Button";
import Typography from "../ui/Typography";

const FixedNavBtn = () => {
  const interviewId = useIntervieweeIdParam();

  const { data: summaryData } = useGetSummary(interviewId);

  const path = usePagePath();

  if (!interviewId || !summaryData) return null;

  return (
    <div className="fixed bottom-12 w-full flex justify-center">
      <div className="flex gap-2 bg-background-fixedBtnBackground p-1 rounded-veda_border_radius_4xl">
        <Button
          variant={path == PAGE_PATH_ALIAS.SUMMARY ? "outline_black" : "gray"}
          to={PAGE_ROUTES.INTERVIEW.SUMMARY(interviewId)}
          disabled={path == PAGE_PATH_ALIAS.SUMMARY}
          className="w-[230px] h-[76px]"
          hover={"none"}
          rounded={"xl4"}
        >
          <Typography size={"content2"} fontColor={"heading"}>
            Summary
          </Typography>
        </Button>
        <Button
          variant={path == PAGE_PATH_ALIAS.QUESTIONS ? "outline_black" : "gray"}
          disabled={path == PAGE_PATH_ALIAS.QUESTIONS}
          to={PAGE_ROUTES.INTERVIEW.QUESTIONS(interviewId)}
          className="w-[230px] h-[76px]"
          hover={"none"}
          rounded={"xl4"}
        >
          <Typography size={"content2"} fontColor={"heading"}>
            Question
          </Typography>
        </Button>
        {summaryData.interviewScript && (
          <Button
            variant={path == PAGE_PATH_ALIAS.LIST ? "outline_black" : "gray"}
            disabled={path == PAGE_PATH_ALIAS.LIST}
            to={PAGE_ROUTES.ARTICLE.LIST(interviewId)}
            className="w-[230px] h-[76px]"
            hover={"none"}
            rounded={"xl4"}
          >
            <Typography size={"content2"} fontColor={"heading"}>
              Articles
            </Typography>
          </Button>
        )}
      </div>
    </div>
  );
};

export default FixedNavBtn;
