// Modal.tsx
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import useModalStore from "@/store/modalStore";

export const BasicModalContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="bg-background-gray06 border-background-gray05 rounded-veda_border_radius px-12 py-10 flex flex-col overflow-auto">
      {children}
    </div>
  );
};

const Modal: React.FC = () => {
  const { isOpen, content, visible, setVisible, setIsOpen } = useModalStore();
  const animationDuration = 500;

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        setIsOpen(false);
      }, animationDuration);
    }
  }, [visible, setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setVisible(true);
      }, 0);
    }
  }, [isOpen, setVisible]);

  const onRequestClose = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    e.stopPropagation();
    setVisible(false);
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className={`fixed inset-0 flex items-center justify-center transition-opacity duration-${animationDuration} ${
        visible ? "opacity-100" : "opacity-0"
      } bg-black/70`} // 백드롭에 투명도를 설정
      onClick={onRequestClose}
      role="dialog"
    >
      <div
        className={`transition-all duration-${animationDuration} ${
          visible ? "translate-y-0 opacity-100" : "translate-y-[20%] opacity-0"
        }`} // 내부 요소는 투명도 없이 배경색을 설정
        onClick={(e) => e.stopPropagation()} // Prevent click propagation to the backdrop
      >
        {content}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
