// useGetArticles.ts
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "@/constants/queryKey";
import { getArticles } from "@/api/article";

export const useGetArticles = (intervieweeId: number | undefined) => {
  return useQuery({
    queryKey: QUERY_KEYS.ARTICLES(intervieweeId!), // intervieweeId를 queryKey로 사용
    retry: false,
    queryFn: () => getArticles({ interviewee_id: intervieweeId }), // getArticlesByInterviewee에 intervieweeId 전달
    refetchInterval: 1000 * 30,
    // staleTime: 1000 * 60,
    enabled: !!intervieweeId, // intervieweeId가 있을 때만 쿼리 실행
  });
};
