import ContentBox from "@/components/ui/ContentBox";
import React from "react";
import KeyMomentsSourceBox from "./KeyMomentsSourceBox";
import { KeyMoment } from "@/@types/interviewee";
import Typography from "@/components/ui/Typography";

const KeyMomentsBox = ({ name, description, references }: KeyMoment) => {
  const slicedSources = references.slice(0, 2);

  return (
    <ContentBox className="flex flex-col">
      <div className="flex gap-[96px] flex-1 h-[200px]">
        <div className="flex-1 flex flex-col gap-xlarge_gap">
          <Typography
            size={"heading4"}
            fontColor={"primary"}
            weight={"semibold"}
          >
            {name}
          </Typography>
          <div className="flex-1 overflow-y-auto">
            <Typography
              size={"content2"}
              fontColor={"primary"}
              lineHeight={"snug"}
              weight={"normal"}
            >
              {description}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col gap-default_gap">
          {slicedSources.map((source, index) => (
            <KeyMomentsSourceBox
              key={source.content + index}
              sourceImg={""}
              sourceText={source.content}
              sourceLink={source.url}
            />
          ))}
        </div>
      </div>
    </ContentBox>
  );
};

export default KeyMomentsBox;
