// useModalStore.ts
import { create } from "zustand";

interface ModalState {
  isOpen: boolean;
  visible: boolean;
  content: React.ReactNode | null;
  openModal: ({ component }: { component: React.ReactNode }) => void;
  setVisible: (visible: boolean) => void;
  setIsOpen: (isOpen: boolean) => void;
  closeModal: () => void;
}

const useModalStore = create<ModalState>((set) => ({
  isOpen: false,
  visible: false,
  content: null,
  setVisible: (visible: boolean) => set({ visible: visible }),
  setIsOpen: (isOpen: boolean) => set({ isOpen: isOpen }),
  openModal: ({ component }: { component: React.ReactNode }) =>
    set({ isOpen: true, content: component }),
  closeModal: () => set({ content: null, visible: false }),
}));

export default useModalStore;
