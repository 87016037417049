// hooks/useLinkedinInput.ts
import { linkedInProfileUrlValidator } from "@/utils/validation/isLinkedInProfileUrl";
import React from "react";
import useToast from "../ui/useToast";
import useLinkFormStore from "@/store/linkFormStore";

const useLinkedinInput = ({
  onLinkedinInputComplete,
  onResetLinkedInForm,
}: {
  onLinkedinInputComplete: (linkedin: string) => void;
  onResetLinkedInForm: () => void;
}) => {
  const { linkedinInput, setLinkedinInput, setLinkedIn, resetLinkedinInput } =
    useLinkFormStore();

  const { showToast } = useToast();

  const addToLinkedInForm = () => {
    const linkedInErrorMsg = linkedInProfileUrlValidator(linkedinInput);

    if (linkedInErrorMsg) {
      showToast({
        type: "error",
        message: linkedInErrorMsg,
      });
      return;
    }

    setLinkedIn(linkedinInput); // 상태 업데이트
    onLinkedinInputComplete(linkedinInput);
  };

  const onSubmitLinkedinInputForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addToLinkedInForm();
  };

  const onClickLinkedinBtn = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    addToLinkedInForm();
  };

  const onChangeLinkedinInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLinkedinInput(value);
  };

  const onClickInputDeleteBtn = () => {
    resetLinkedinInput();
    onResetLinkedInForm();
  };

  return {
    linkedinInput,
    setLinkedinInput,
    onSubmitLinkedinInputForm,
    onChangeLinkedinInput,
    onClickLinkedinBtn,
    onClickInputDeleteBtn,
    resetLinkedinInput,
  };
};

export default useLinkedinInput;
