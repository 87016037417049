import { ReactComponent as AiSparkleIcon } from "#/public/icon/ai_sparkle.svg";
import ArticleContentLayout from "@/components/article/ArticleContentLayout";
import { ArticleTopicButton } from "@/components/article/ArticleTopicButton";
import Header from "@/components/Header";
import { Button } from "@/components/ui/Button";
import { Input } from "@/components/ui/Input";
import { PageLayout } from "@/components/ui/Layout";
import { Textarea } from "@/components/ui/TextArea";
import Typography from "@/components/ui/Typography";
import { PAGE_ROUTES } from "@/constants/routeName";
import useArticleCreatePage from "@/hooks/article/useArticleCreatePage";
import useScrollTopOnMount from "@/hooks/ui/useScrollTopOnMount";
import { useNavigate } from "react-router-dom";
import ErrorPage from "../@common/ErrorPage";
import LoadingPage from "../@common/LoadingPage";
import ArticleTopicSelect from "@/components/article/ArticleTopicSelect";

const ArticleCreatePage = () => {
  useScrollTopOnMount();

  const navigate = useNavigate();

  const {
    intervieweeId,
    prompts,
    fullName,
    customPrompt,
    customPromptSectionTitle,
    selectedTopicIndex,
    isTotalLoading,
    isInvalid,
    isCreatingArticle,
    onChangeCustomPrompt,
    onClickGenerateArticleBtn,
    onClickPromptBox,
  } = useArticleCreatePage();

  if (isTotalLoading) {
    return <LoadingPage />;
  }

  if (isInvalid) {
    return (
      <ErrorPage
        error={{
          message: "잘못된 접근입니다.",
        }}
        buttonLabel={"Go back to home"}
        onRetry={() => navigate(PAGE_ROUTES.HOME)}
      />
    );
  }

  const onClickArrow = () => {
    navigate(PAGE_ROUTES.ARTICLE.LIST(intervieweeId!));
  };

  return (
    <>
      <Header />
      <PageLayout
        title={
          <PageLayout.Title onClickArrow={onClickArrow}>
            <div className="flex-1 flex justify-between items-center">
              <div className="flex items-center gap-3">
                <PageLayout.TitleText>Generate article of</PageLayout.TitleText>
                <PageLayout.TitleText fontColor={"green_primary"}>
                  {fullName}
                </PageLayout.TitleText>
              </div>
            </div>
          </PageLayout.Title>
        }
      >
        <ArticleContentLayout title="Choose topic">
          <ArticleTopicSelect
            prompts={prompts}
            selectedTopicIndex={selectedTopicIndex}
            onClickPromptBox={onClickPromptBox}
          />
        </ArticleContentLayout>
        <div className="mt-20" />
        <ArticleContentLayout title={customPromptSectionTitle}>
          <div className="flex">
            <Textarea
              className="flex-1"
              size={"lg"}
              placeholder="Try to focus on the career at Google."
              value={customPrompt}
              onChange={onChangeCustomPrompt}
              textAreaClassName="leading-snug"
              rows={7}
            />
          </div>
        </ArticleContentLayout>

        <div className="mt-16" />
        <div className="flex justify-center">
          <Button
            variant={"gradient_background"}
            onClick={onClickGenerateArticleBtn}
            className="flex gap-2 w-[320px] h-[72px]"
            isLoading={isCreatingArticle}
          >
            <Typography size={"content1"}>Generate New Article</Typography>
            <AiSparkleIcon className="ml-2" />
          </Button>
        </div>
      </PageLayout>
    </>
  );
};

export default ArticleCreatePage;
