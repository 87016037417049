import { API_ROUTES } from "@/constants/routeName";
import { api } from "@/lib/axios";
import {
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  RegisterResponse,
} from "./types";

export const login = async (body: LoginRequest): Promise<LoginResponse> => {
  return api.post(API_ROUTES.AUTH.LOGIN, body);
};

export const register = async (
  body: RegisterRequest
): Promise<RegisterResponse> => {
  return api.post(API_ROUTES.AUTH.SIGNUP, body);
};
