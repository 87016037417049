// pages/LoginPage.tsx
import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import useLogin from "@/hooks/auth/useLogin";
import { Button } from "@/components/ui/Button";
import Typography from "@/components/ui/Typography";
import { useAuth } from "@/context/AuthContext";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "@/constants/routeName";
import { Input } from "@/components/ui/Input";
import useToast from "@/hooks/ui/useToast";

const loginSchema = z.object({
  email: z.string().email("Invalid email address"),
  password: z.string().min(6, "Password must be at least 6 characters"),
});

type LoginFormValues = z.infer<typeof loginSchema>;

const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    resolver: zodResolver(loginSchema),
  });

  const { login, logout } = useAuth();

  const { mutate, isPending, isError, error } = useLogin();

  const { showToast } = useToast();

  const navigate = useNavigate();

  const onSubmit = (data: LoginFormValues) => {
    mutate(data, {
      onSuccess: (data, { email }) => {
        login({
          accessToken: data.accessToken,
          email,
        });
      },
      onError: () => {
        logout();
        showToast({
          type: "error",
          message: "로그인에 실패했습니다. 이메일과 비밀번호를 확인해주세요.",
        });
      },
    });
  };

  return (
    <div>
      <h1>Login Page</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="email">Email</label>
          <Input id="email" type="email" {...register("email")} />
          {errors.email && <p>{errors.email.message}</p>}
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <Input id="password" type="password" {...register("password")} />
          {errors.password && <p>{errors.password.message}</p>}
        </div>
        <Button type="submit" isLoading={isPending}>
          <Typography>Login</Typography>
        </Button>
        {isError && <p>{error?.message || "An error occurred"}</p>}
      </form>
      <Button onClick={() => navigate(PAGE_ROUTES.REGISTER)}>
        GO TO REGISTER
      </Button>
    </div>
  );
};

export default LoginPage;
