import { ReactComponent as Refresh } from "#/public/icon/refresh.svg";
import { useGetInterviewees } from "@/hooks/home/useGetInterviewees";
import { Button } from "@/components/ui/Button";
import InterviewList from "./InterviewList";
import useLastFetchedTime from "@/hooks/home/useLastFetchedTime";
import Typography from "@/components/ui/Typography";

const getFetchStatus = ({
  isFetching,
  isError,
  lastFetchTime,
}: {
  isFetching: boolean;
  isError: boolean;
  lastFetchTime: number | undefined;
}) => {
  if (isFetching) {
    return "동기화 중...";
  } else if (isError) {
    return "동기화 실패";
  } else if (lastFetchTime) {
    return "마지막 업데이트: " + new Date(lastFetchTime).toLocaleString();
  } else {
    return "없음";
  }
};

const InterviewListSection = ({
  targetRef,
}: {
  targetRef: React.RefObject<HTMLDivElement>;
}) => {
  const { isError, refetch, isFetching, isSuccess } = useGetInterviewees();

  const lastFetchTime = useLastFetchedTime({ isFetching, isSuccess });

  const fetchMessage = getFetchStatus({
    isFetching,
    isError,
    lastFetchTime,
  });

  const onClickRetry = () => {
    refetch();
  };

  return (
    <div
      className="mx-container_x min-h-screen pt-header_content_spacing pb-[212px] flex flex-col"
      ref={targetRef}
    >
      <div className="flex items-center gap-4">
        <Typography size={"heading2"} weight={"semibold"} fontColor={"primary"}>
          Interview Database
        </Typography>
        <Button
          variant={"gray"}
          size={"icon"}
          rounded={"xl"}
          disabled={isFetching}
          onClick={onClickRetry}
          aria-label="interview-list-refresh-button"
        >
          <Refresh></Refresh>
        </Button>
        <Typography>{fetchMessage}</Typography>
      </div>
      <div className="mt-16" />
      <div className="flex flex-col gap-5 flex-1">
        <InterviewList />
      </div>
    </div>
  );
};

export default InterviewListSection;
