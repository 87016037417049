import { useGetInterviewees } from "@/hooks/home/useGetInterviewees";
import { ReactComponent as AddIcon } from "#/public/icon/showMore.svg";
import InterviewListItem from "./InterviewListItem";
import { Button } from "@/components/ui/Button";
import LoadingPage from "@/pages/@common/LoadingPage";
import { FullSizeCenter } from "@/components/ui/FullScreenCenter";

const InterviewList = () => {
  const {
    data: interviewees,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useGetInterviewees();

  if (isLoading || (isError && isFetching)) return <LoadingPage isRelative />;

  if (isError || !interviewees)
    return (
      <FullSizeCenter>
        <div className="flex flex-col gap-4">
          Error: {error?.message}
          <Button
            onClick={() => {
              refetch();
            }}
          >
            Retry
          </Button>
        </div>
      </FullSizeCenter>
    );

  const onClickAddIcon = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {interviewees
        .sort((a, b) => {
          if (a.createdAt < b.createdAt) return 1;
          if (a.createdAt > b.createdAt) return -1;
          return 0;
        })
        .map((interviewee, idx) => {
          return <InterviewListItem key={interviewee.id} {...interviewee} />;
        })}
      <div className="h-16"></div>
      <div className="flex justify-center">
        <AddIcon className="cursor-pointer" onClick={onClickAddIcon} />
      </div>
    </>
  );
};

export default InterviewList;
