export const MUTATION_KEYS = {
  CREATE_ARTICLE: (intervieweeId: number) => ["CREATE_ARTICLE", intervieweeId],
  UPLOAD_AUDIO_TO_S3: (intervieweeId: number) => [
    "UPLOAD_AUDIO_TO_S3",
    intervieweeId,
  ],
  PATCH_USER_SUMMARY: (intervieweeId: number) => [
    "PATCH_USER_SUMMARY",
    intervieweeId,
  ],
} as const;
