import ProfileImg from "@/components/ui/ProfileImg";
import Typography from "@/components/ui/Typography";
import ContentBoxForSummary from "../ContentBoxForSummary";

const CompanyPeopleBoxItem = ({
  people,
}: {
  people: {
    name: string;
    position: string;
    img: string;
  };
}) => {
  return (
    <div className="flex gap-default_gap">
      <ProfileImg
        width="w-[48px]"
        height="h-[48px]"
        img="https://source.unsplash.com/random"
      />
      <div className="flex-1 flex flex-col justify-center gap-1">
        <Typography size="small1" weight={"semibold"}>
          {people.name}
        </Typography>
        <Typography size="small2" weight={"normal"}>
          {people.position}
        </Typography>
      </div>
    </div>
  );
};

const CompanyPeopleBox = ({
  people,
  title,
}: {
  title: string;
  people: {
    name: string;
    position: string;
    img: string;
  }[];
}) => {
  return (
    <ContentBoxForSummary title={title} classNameForContentBox="flex-1">
      <div className="flex flex-col gap-medium_gap">
        {people.map((people, index) => (
          <CompanyPeopleBoxItem
            key={index}
            people={people}
          ></CompanyPeopleBoxItem>
        ))}
      </div>
    </ContentBoxForSummary>
  );
};

export default CompanyPeopleBox;
