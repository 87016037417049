import React from "react";
import FullScreenCenter, {
  FullSizeCenter,
} from "@/components/ui/FullScreenCenter";
import { Button } from "@/components/ui/Button";
import Typography from "@/components/ui/Typography";

const ErrorPage = ({
  error,
  onRetry,
  isRelative = false,
  buttonLabel = "Retry",
}: {
  error?: { message: string };
  onRetry: () => void;
  buttonLabel?: string;
  isRelative?: boolean;
}) => {
  const Comp = isRelative ? FullSizeCenter : FullScreenCenter;

  return (
    <Comp>
      <div className="flex flex-col gap-4 items-center">
        <Typography>{error?.message}</Typography>
        <Button onClick={onRetry}>{buttonLabel}</Button>
      </div>
    </Comp>
  );
};

export default ErrorPage;
