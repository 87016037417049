import { useGetArticles } from "@/hooks/article/useGetArticles";
import useIntervieweeIdParam from "@/hooks/useIntervieweeIdParam";
import ErrorPage from "@/pages/@common/ErrorPage";
import LoadingPage from "@/pages/@common/LoadingPage";
import ArticleListItem from "./ArticleListItem";
import ArticleContentLayout from "./ArticleContentLayout";
import ContentBox from "../ui/ContentBox";
import Typography from "../ui/Typography";

const ArticleList = () => {
  const id = useIntervieweeIdParam();

  const {
    data: articles,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useGetArticles(id);

  if (isLoading) {
    return <LoadingPage isRelative />;
  }

  if ((isError && !isFetching) || !articles) {
    return (
      <ErrorPage
        onRetry={refetch}
        isRelative
        error={{
          message: error?.message || "Failed to load articles",
        }}
      />
    );
  }

  return (
    <div>
      <ArticleContentLayout title="Generated articles">
        {articles.length === 0 ? (
          <ContentBox
            type={"content_interview"}
            className="flex justify-center items-center h-[200px]"
          >
            <Typography
              size={"content1"}
              fontColor={"disabled"}
              weight={"medium"}
            >
              No Article Generated
            </Typography>
          </ContentBox>
        ) : (
          <div className="grid grid-cols-2 gap-5">
            {articles.map((article) => (
              <ArticleListItem key={article.id} {...article} />
            ))}
          </div>
        )}
      </ArticleContentLayout>
    </div>
  );
};

export default ArticleList;
