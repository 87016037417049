import CompanyStatBoxItem from "./CompanyStatBoxItem";

const CompanyStatBox = ({
  stats,
}: {
  stats: {
    metric: {
      name: string;
      value: string | number;
    }[];
  };
}) => {
  return (
    <div className="grid grid-cols-2 gap-summary_company_item">
      {stats.metric.map((item, index) => (
        <CompanyStatBoxItem
          key={index}
          title={item.name}
          content={item.value}
        />
      ))}
    </div>
  );
};

export default CompanyStatBox;
