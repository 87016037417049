// pages/RegisterPage.tsx
import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/Button";
import Typography from "@/components/ui/Typography";
import { useAuth } from "@/context/AuthContext";
import useRegister from "@/hooks/auth/useRegister";
import { Input } from "@/components/ui/Input";
import useToast from "@/hooks/ui/useToast";

const registerSchema = z.object({
  email: z.string().email("Invalid email address"),
  name: z.string().min(1, "Name is required"),
  password: z.string().min(6, "Password must be at least 6 characters"),
});

type RegisterFormValues = z.infer<typeof registerSchema>;

const RegisterPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormValues>({
    resolver: zodResolver(registerSchema),
  });

  const navigate = useNavigate();
  const { login } = useAuth();

  const { showToast } = useToast();

  const { mutate, isPending, isError, error } = useRegister();

  const onSubmit = (data: RegisterFormValues) => {
    mutate(data, {
      onSuccess: (data, { email }) => {
        login({
          accessToken: data.accessToken,
          email,
        });
        navigate("/");
      },
      onError: () => {
        showToast({
          message: "회원가입에 실패했습니다. 다시 시도해주세요.",
          type: "error",
        });
      },
    });
  };

  return (
    <div>
      <h1>Register Page</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="email">Email</label>

          <Input id="email" type="email" {...register("email")} />
          {errors.email && <p>{errors.email.message}</p>}
        </div>
        <div>
          <label htmlFor="name">Name</label>
          <Input id="name" {...register("name")} />
          {errors.name && <p>{errors.name.message}</p>}
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <Input id="password" {...register("password")} />
          {errors.password && <p>{errors.password.message}</p>}
        </div>
        <Button type="submit" isLoading={isPending}>
          <Typography>Register</Typography>
        </Button>
        {isError && <p>{error?.message || "An error occurred"}</p>}
      </form>
      <div>
        <Typography>Already have an account?</Typography>
        <Button onClick={() => navigate("/login")}>
          <Typography>Login</Typography>
        </Button>
      </div>
    </div>
  );
};

export default RegisterPage;
