import React, { useState } from "react";
import { useGetSummary } from "../summary/useGetSummary";
import useToast from "../ui/useToast";
import useIntervieweeIdParam from "../useIntervieweeIdParam";
import { useCreateArticle } from "./useCreateArticle";
import { useGetPromptsForArticle } from "./useGetPromptsForArticle";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "@/constants/routeName";
import { articlePromptMap } from "@/constants/promptsMap";

const useArticleCreatePage = () => {
  const intervieweeId = useIntervieweeIdParam();

  const { showToast } = useToast();

  const navigate = useNavigate();

  const { data: prompts, isLoading: isLoadingPrompt } =
    useGetPromptsForArticle();

  const { data: summary, isLoading: isLoadingSummary } =
    useGetSummary(intervieweeId);

  const { mutate: createArticle, isPending: isCreatingArticle } =
    useCreateArticle();

  const [selectedTopicIndex, setSelectedTopicIndex] = useState<number | null>(
    null
  );
  const [customPrompt, setCustomPrompt] = useState<string>("");

  const isTotalLoading = isLoadingSummary || isLoadingPrompt;
  const isInvalid =
    !intervieweeId || !summary || !summary.interviewScript || !prompts;
  const fullName = summary?.linkedinDetail.full_name;

  const selectedPrompts =
    selectedTopicIndex !== null ? prompts?.[selectedTopicIndex] : null;

  const customPromptSectionTitle =
    selectedPrompts?.name ===
    articlePromptMap.article_generation_FullyCustomized.name
      ? "Edit Prompt"
      : "Add Custom Prompt";

  const onClickGenerateArticleBtn = () => {
    if (isInvalid) return;

    if (!selectedPrompts) {
      showToast({
        message: "프롬프트를 선택해주세요",
        type: "error",
      });
      return;
    }

    if (
      selectedPrompts.name ===
      articlePromptMap.article_generation_FullyCustomized.name
    ) {
      if (!customPrompt) {
        showToast({
          message: "프롬프트를 입력해주세요",
          type: "error",
        });
        return;
      }

      createArticle(
        {
          intervieweeId: intervieweeId!,
          systemPrompt: customPrompt,
        },
        {
          onSuccess: () => {
            setSelectedTopicIndex(null);
            setCustomPrompt("");
            navigate(PAGE_ROUTES.ARTICLE.LIST(intervieweeId!));
          },
        }
      );

      return;
    }

    createArticle(
      {
        intervieweeId: intervieweeId!,
        systemPrompt:
          selectedPrompts.content +
          `<key requirements>${customPrompt}</key requirements>`,
      },
      {
        onSuccess: () => {
          setSelectedTopicIndex(null);
          setCustomPrompt("");
          navigate(PAGE_ROUTES.ARTICLE.LIST(intervieweeId!));
        },
      }
    );
  };

  const onChangeCustomPrompt = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCustomPrompt(e.target.value);
  };

  const onClickPromptBox = (index: number) => {
    setSelectedTopicIndex(index);

    let selectedPrompts = prompts?.[index];

    if (
      selectedPrompts?.name ===
      articlePromptMap.article_generation_FullyCustomized.name
    ) {
      setCustomPrompt(selectedPrompts?.content || "");
      return;
    }

    setCustomPrompt("");
  };

  return {
    intervieweeId,
    fullName,
    isTotalLoading,
    isInvalid,
    isCreatingArticle,
    prompts,
    selectedTopicIndex,
    customPromptSectionTitle,
    customPrompt,
    setSelectedTopicIndex,
    setCustomPrompt,
    onChangeCustomPrompt,
    onClickGenerateArticleBtn,
    onClickPromptBox,
  };
};

export default useArticleCreatePage;
