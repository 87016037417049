import { PAGE_PATH_ALIAS } from "@/constants/pagePathAlias";
import { PAGE_ROUTES } from "@/constants/routeName";

export const getPagePath = (pathname: string): string => {
  switch (true) {
    case pathname === PAGE_ROUTES.HOME:
      return PAGE_PATH_ALIAS.HOME;
    case pathname === PAGE_ROUTES.TEST:
      return PAGE_PATH_ALIAS.TEST;
    case pathname === PAGE_ROUTES.LOGIN:
      return PAGE_PATH_ALIAS.LOGIN;
    case pathname === PAGE_ROUTES.REGISTER:
      return PAGE_PATH_ALIAS.REGISTER;
    case new RegExp(`^${PAGE_ROUTES.INTERVIEW.SUMMARY("\\d+")}$`).test(
      pathname
    ):
      return PAGE_PATH_ALIAS.SUMMARY;
    case new RegExp(`^${PAGE_ROUTES.INTERVIEW.QUESTIONS("\\d+")}$`).test(
      pathname
    ):
      return PAGE_PATH_ALIAS.QUESTIONS;
    case new RegExp(`^${PAGE_ROUTES.ARTICLE.LIST("\\d+")}$`).test(pathname):
      return PAGE_PATH_ALIAS.LIST;
    case new RegExp(`^${PAGE_ROUTES.ARTICLE.CREATE("\\d+")}$`).test(pathname):
      return PAGE_PATH_ALIAS.CREATE;
    default:
      throw new Error("Unknown page path");
  }
};
