import React from "react";
import Typography, { TypographyProps } from "./Typography";
import { cn } from "@/utils";
import Container from "./Container";
import { ReactComponent as ArrowLeft } from "#/public/icon/arrowLeft.svg";

export const PageTitle = ({
  children,
  onClickArrow,
}: {
  children: React.ReactNode;
  onClickArrow?: () => void;
}) => {
  return (
    <div className="flex items-center gap-4 h-16">
      <ArrowLeft onClick={onClickArrow} className="cursor-pointer" />
      {children}
    </div>
  );
};

export const PageTitleText = ({
  children,
  className,
  fontColor,
}: {
  children: React.ReactNode;
  className?: string;
  fontColor?: TypographyProps["fontColor"];
}) => {
  return (
    <Typography
      size="heading2"
      weight="semibold"
      className={cn(className)}
      {...(fontColor && { fontColor })}
    >
      {children}
    </Typography>
  );
};

export const PageLayout = ({
  title,
  children,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <ContentLayout>
      <Typography size={"heading"} weight={"semibold"}>
        {title}
      </Typography>
      <div className="mt-16" />
      {children}
    </ContentLayout>
  );
};

PageLayout.Title = PageTitle;
PageLayout.TitleText = PageTitleText;

export const ContentLayout = ({
  children,
  className,
  padding = "py-header_content_spacing",
}: {
  children: React.ReactNode;
  className?: string;
  padding?: string;
}) => {
  return <Container className={cn(padding, className)}>{children}</Container>;
};
