import { useEffect, useState } from "react";

const useLastFetchedTime = ({
  isFetching,
  isSuccess,
}: {
  isFetching: boolean;
  isSuccess: boolean;
}) => {
  const [lastFetchTime, setLastFetchTime] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (!isFetching && isSuccess) {
      setLastFetchTime(Date.now());
    }
  }, [isFetching, isSuccess, setLastFetchTime]);

  return lastFetchTime;
};

export default useLastFetchedTime;
