import { IntervieweeType, LinkForm } from "@/@types/interviewee";
import { createInterviewee } from "@/api/interviewee";
import { QUERY_KEYS } from "@/constants/queryKey";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useToast from "../ui/useToast";
import { useOnError } from "../useOnError";
import { useGetIntervieweesItem } from "./useGetInterviewees";

export const useSubmitLinkForm = ({
  onInterviewCreatedSuccess,
}: {
  onInterviewCreatedSuccess: () => void;
}) => {
  const { handleError } = useOnError();

  const { showToast } = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (linkForm: LinkForm) => {
      return createInterviewee({
        ...linkForm,
      });
    },
    onSuccess: (data) => {
      showToast({
        message: "Interviewee created successfully",
        type: "success",
      });

      queryClient.setQueryData<useGetIntervieweesItem[]>(
        QUERY_KEYS.INTERVIEWEES,
        (prevData) => {
          if (!prevData) return [];

          const newData: useGetIntervieweesItem[] = [
            {
              ...data,
              type: IntervieweeType.CREATING,
              isInterviewScriptExist: false,
              isKeyMomentsExist: false,
            },
            ...prevData,
          ];

          return newData;
        }
      );

      queryClient.refetchQueries({
        queryKey: QUERY_KEYS.INTERVIEWEES,
      });

      onInterviewCreatedSuccess();
    },
    onError: (error) => {
      handleError(error, (e: Error) => {
        showToast({
          message: "Failed to create interviewee. Please try again.",
          type: "error",
        });
      });
    },
  });
};
