import useGetIntervieweeIdParam from "@/hooks/summary/useIntervieweeId";
import { useGetSummaryProfileData } from "@/hooks/summary/useGetSummary";
import ProfileImg from "../../ui/ProfileImg";
import Typography from "../../ui/Typography";

const PeopleSectionProfile = () => {
  const id = useGetIntervieweeIdParam();

  const profile = useGetSummaryProfileData(id);

  if (!profile) return null;

  const profileEducationData = profile?.education?.map((item) => ({
    profileImg: item.logo_url,
    major: item.degree_name,
    university: item.school,
  }));

  const name =
    profile.full_name ||
    profile.first_name + " " + profile.last_name ||
    "Anonymous";
  const profileImg = profile.profile_pic_url || null;
  const position = profile.headline;

  return (
    <div className="flex-none flex gap-10">
      <ProfileImg img={profileImg} width="w-[120px]" height="h-[120px]" />
      <div className="flex flex-col gap-3 justify-center">
        <Typography size={"heading4"} fontColor={"heading"}>
          {name}
        </Typography>
        <Typography size={"content2"} fontColor={"heading"} weight={"normal"}>
          {position}
        </Typography>
      </div>
      {/* <div className="flex-1 self-stretch flex justify-center items-center">
          <Divider
            color={"gray05"}
            direction={"vertical"}
            className="h-[70%]"
          />
        </div> */}
      {/* <div className="w-[40%] flex-none flex flex-col gap-6 justify-center h-[200px] overflow-y-auto pr-8">
          {profileEducationData?.map((item, idx) => (
            <ProfileEducationItem
              key={item.major || "df" + idx}
              profileImg={item.profileImg}
              major={item.major}
              university={item.university}
            />
          ))}
        </div> */}
      {/* <div className="mt-[120px]" /> */}
      {/* <CareerPath /> */}
    </div>
  );
};

export default PeopleSectionProfile;
