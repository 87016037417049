import useIntervieweeIdParam from "../useIntervieweeIdParam";
import { useIsMutating } from "@tanstack/react-query";
import { MUTATION_KEYS } from "@/constants/mutationKey";

const useIsUploadScriptLoading = () => {
  const intervieweeId = useIntervieweeIdParam();

  // 각 mutation의 진행 상태를 추적
  const isUploadingAudio = useIsMutating({
    mutationKey: MUTATION_KEYS.UPLOAD_AUDIO_TO_S3(intervieweeId!),
  });

  const isPatchingSummary = useIsMutating({
    mutationKey: MUTATION_KEYS.PATCH_USER_SUMMARY(intervieweeId!),
  });

  const isLoading = isUploadingAudio > 0 || isPatchingSummary > 0;

  return isLoading;
};

export default useIsUploadScriptLoading;
