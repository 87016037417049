import { uploadToS3 } from "@/api/interviewee";
import { MUTATION_KEYS } from "@/constants/mutationKey";
import { useMutation } from "@tanstack/react-query";
import useIntervieweeIdParam from "../useIntervieweeIdParam";
import useGetIntervieweeId from "./useIntervieweeId";

interface UseFileUploadOptions {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

const useUploadAudioToS3 = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) => {
  const intervieweeId = useGetIntervieweeId();

  return useMutation({
    mutationKey: MUTATION_KEYS.UPLOAD_AUDIO_TO_S3(intervieweeId!),
    mutationFn: ({
      file,
      intervieweeId,
    }: {
      file: File;
      intervieweeId: number | undefined;
    }) => {
      if (!intervieweeId) {
        return Promise.reject(new Error("Interviewee ID is required."));
      }
      return uploadToS3(file, intervieweeId);
    },
    onSuccess,
    onError,
  });
};

export default useUploadAudioToS3;
