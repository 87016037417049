import { ReactComponent as CheckIcon } from "#/public/icon/check.svg";
import { ReactComponent as LinkedInDeleteIcon } from "#/public/icon/delete_linkedIn.svg";
import { Button } from "@/components/ui/Button";
import { Input } from "@/components/ui/Input";
import Typography from "@/components/ui/Typography";
import useLinkedinInput from "@/hooks/home/useLinkedinInput";
import useLinkForm from "@/hooks/home/useLinkForm";
import { cn } from "@/utils";

const LinkedInInputForm = () => {
  const {
    linkForm: { linkedin },
    handleChangeLinkedInForm,
  } = useLinkForm();

  const {
    linkedinInput,
    onChangeLinkedinInput,
    onClickInputDeleteBtn,
    onClickLinkedinBtn,
    onSubmitLinkedinInputForm,
  } = useLinkedinInput({
    onLinkedinInputComplete: (linkedin) => {
      handleChangeLinkedInForm(linkedin);
    },
    onResetLinkedInForm: () => {
      handleChangeLinkedInForm("");
    },
  });

  return (
    <form className="flex gap-3" onSubmit={onSubmitLinkedinInputForm}>
      <div className="relative flex-1 flex">
        <Input
          className={cn("flex-1", {
            "border-2 border-input-active": Boolean(linkedin),
            "pr-20": Boolean(linkedin),
          })}
          value={linkedinInput}
          placeholder="www.linkedin.com/in/tykim90/"
          onChange={onChangeLinkedinInput}
          disabled={Boolean(linkedin)}
        />
        {linkedin && (
          <LinkedInDeleteIcon
            role="button"
            aria-label="linkedin-delete-icon"
            className="absolute right-4 top-0 bottom-0 m-auto cursor-pointer hover:opacity-90 transition-all duration-150"
            onClick={onClickInputDeleteBtn}
          />
        )}
      </div>
      <Button
        variant={"secondary"}
        size={"lg"}
        className="w-[15%] h-[64px]"
        onClick={onClickLinkedinBtn}
      >
        {linkedin ? (
          <CheckIcon
            className="w-8 h-8"
            aria-label="linkedin-checked-icon"
            role="button"
          ></CheckIcon>
        ) : (
          <Typography size={"content2"}>Enter</Typography>
        )}
      </Button>
    </form>
  );
};

export default LinkedInInputForm;
