export function formatISOStringToCustomDate(
  isoString: string | null | undefined
): string | null {
  // isoString이 null 또는 undefined인지 확인
  if (!isoString) {
    return null;
  }

  const date = new Date(isoString);

  // 유효한 날짜인지 확인
  if (isNaN(date.getTime())) {
    return null;
  }

  const year = date.getFullYear().toString().slice(2); // 연도의 마지막 두 자리
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더하고 두 자리로 만듦
  const day = date.getDate().toString().padStart(2, "0"); // 일을 두 자리로 만듦

  return `${year}.${month}.${day}`;
}
