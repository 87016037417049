import { ReactComponent as AiSparkleIcon } from "#/public/icon/ai_sparkle.svg";
import ArticleList from "@/components/article/ArticleList";
import Header from "@/components/Header";
import { Button } from "@/components/ui/Button";
import { PageLayout } from "@/components/ui/Layout";
import Typography from "@/components/ui/Typography";
import { PAGE_ROUTES } from "@/constants/routeName";
import { useGetSummary } from "@/hooks/summary/useGetSummary";
import useScrollTopOnMount from "@/hooks/ui/useScrollTopOnMount";
import useIntervieweeIdParam from "@/hooks/useIntervieweeIdParam";
import { useNavigate } from "react-router-dom";
import ErrorPage from "../@common/ErrorPage";
import LoadingPage from "../@common/LoadingPage";
import { useIsCreateArticleLoading } from "@/hooks/article/useCreateArticle";
import Spinner from "@/components/ui/Spinner";
import FixedNavBtn from "@/components/summary/FixedNavBtn";

const ArticleListPage = () => {
  useScrollTopOnMount();

  const navigate = useNavigate();

  const intervieweeId = useIntervieweeIdParam();

  const { data: summary, isLoading } = useGetSummary(intervieweeId);

  const isCreateArticleLoading = useIsCreateArticleLoading({
    intervieweeId: intervieweeId!,
  });

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!intervieweeId || !summary) {
    return (
      <ErrorPage
        error={{
          message: "잘못된 접근입니다.",
        }}
        buttonLabel={"Go back to home"}
        onRetry={() => navigate(PAGE_ROUTES.HOME)}
      />
    );
  }

  const onClickArrow = () => {
    navigate(PAGE_ROUTES.INTERVIEW.SUMMARY(intervieweeId));
  };

  const onClickGenerateArticleBtn = () => {
    navigate(PAGE_ROUTES.ARTICLE.CREATE(intervieweeId));
  };

  return (
    <>
      <Header />
      <PageLayout
        title={
          <PageLayout.Title onClickArrow={onClickArrow}>
            <div className="flex-1 flex justify-between items-center">
              <div className="flex items-center gap-3">
                <PageLayout.TitleText>Articles of</PageLayout.TitleText>
                <PageLayout.TitleText fontColor={"green_primary"}>
                  {summary?.linkedinDetail.full_name}
                </PageLayout.TitleText>
              </div>
              {isCreateArticleLoading ? (
                <Button variant={"outline_destructive_fill"} hover={"none"}>
                  <Spinner
                    width="w-6"
                    height="h-6"
                    borderWidth="border-2"
                    color="border-t-background-gray03"
                  />
                  <div className="mr-3"></div>
                  <Typography size={"small1"}>Generating Article...</Typography>
                </Button>
              ) : (
                <Button
                  variant={"gradient_background"}
                  onClick={onClickGenerateArticleBtn}
                >
                  <AiSparkleIcon className="mr-2" />
                  <Typography size={"small1"}>Generate Article</Typography>
                </Button>
              )}
            </div>
          </PageLayout.Title>
        }
      >
        <ArticleList />
        <div className="mt-16" />
        {/* <div className="flex justify-center">
          <Button
            variant={"gradient_background"}
            onClick={onClickGenerateArticleBtn}
            className="flex gap-2 w-[320px] h-[72px]"
          >
            <Typography size={"content1"}>Generate New Article</Typography>
            <AiSparkleIcon className="ml-2" />
          </Button>
        </div> */}
      </PageLayout>
      <FixedNavBtn />
    </>
  );
};

export default ArticleListPage;
