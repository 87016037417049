import { useState } from "react";
import Typography, { TypographyProps } from "../Typography";
import { TypoModal } from "./TypoModal";
import { cn } from "@/utils";

interface TypographyWithHoverModalProps extends TypographyProps {
  modalText?: string;
  modalClassName?: string;
}

const TypographyWithHoverModal = ({
  children,
  className,
  modalClassName,
  modalText,
  ...props
}: TypographyWithHoverModalProps) => {
  const [isModalVisible, setModalVisible] = useState(false); // 모달의 표시 상태

  const handleMouseEnter = () => {
    setModalVisible(true); // 마우스가 들어왔을 때 모달 표시
  };

  const handleMouseLeave = () => {
    setModalVisible(false); // 마우스가 나갔을 때 모달 숨김
  };

  const textForModal = modalText || children; // 모달에 표시할 텍스트

  return (
    <div
      className={cn(className, "relative flex gap-2")}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Typography {...props} className="overflow-hidden">
        {children}
      </Typography>
      {isModalVisible && (
        <TypoModal
          className={cn("top-full", modalClassName)}
          children={textForModal}
        />
      )}
    </div>
  );
};

export default TypographyWithHoverModal;
