import { Question } from "@/@types/interviewee";

type QuestionTheme = Question & {
  questions: Question[];
};

export function mergeQuestionsByTheme(items: Question[]): QuestionTheme[] {
  const questionThemeDic: { [key: string]: QuestionTheme } = {};

  items.forEach((item) => {
    const { name, description } = item;

    const currentCount = questionThemeDic[name]?.questions.length || 0;
    const descriptionIndex = currentCount + 1;
    const indexedDescription = `${descriptionIndex}. ${description}`;

    const newItem = {
      ...item,
      description: indexedDescription,
    };

    if (questionThemeDic[name]) {
      questionThemeDic[name].questions.push(newItem);
    } else {
      questionThemeDic[name] = {
        ...item,
        questions: [newItem],
      };
    }
  });

  // 객체를 다시 배열로 변환
  return Object.values(questionThemeDic);
}
