import { getPagePath } from "@/utils/getPagePath";
import React from "react";
import { useLocation } from "react-router-dom";

const useConfirmPath = () => {
  const location = useLocation();
  const { pathname } = location;

  return getPagePath(pathname);
};

export default useConfirmPath;
