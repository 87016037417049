import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/utils";

const textareaVariants = cva(
  "box-border whitespace-nowrap rounded-veda_border_radius text-md font-medium leading-none",
  {
    variants: {
      variant: {
        primary:
          "bg-input-background placeholder-input-placeholder text-input-foreground caret-background-green01",
      },
      size: {
        default: "px-[24px] py-[20px]",
        sm: "px-[14px] py-[10px]",
        lg: "p-8",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  }
);

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaVariants> {
  minWidth?: string;
  textAreaClassName?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, variant, size, textAreaClassName, ...props }, ref) => {
    return (
      <div className={cn(textareaVariants({ variant, size, className }))}>
        <textarea
          ref={ref}
          className={cn(
            "w-full h-full bg-transparent border-none outline-none resize-none",
            "font-normal tracking-[-0.48px]",
            "focus:ring-0 focus:ring-offset-0",
            "text-[20px] leading-none p-0 m-0",
            textAreaClassName
          )}
          {...props}
        />
      </div>
    );
  }
);

Textarea.displayName = "Textarea";

export { Textarea, textareaVariants };
