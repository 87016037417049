// stores/linkFormStore.ts
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { LinkForm } from "@/@types/interviewee";

interface LinkFormState {
  linkForm: LinkForm;
  linkedinInput: string; // 추가된 상태
  setLinkedIn: (value: string) => void;
  addReference: (reference: string) => void;
  removeReference: (index: number) => void;
  resetLinkForm: () => void;
  setLinkedinInput: (value: string) => void; // 추가된 상태 관리 함수
  resetLinkedinInput: () => void; // 추가된 함수
  resetAll: () => void;
}

const useLinkFormStore = create<LinkFormState>()(
  devtools((set) => ({
    linkForm: {
      linkedin: "",
      references: [],
    },
    linkedinInput: "", // 초기 값 설정

    setLinkedIn: (value: string) => {
      set((state) => ({
        linkForm: { ...state.linkForm, linkedin: value },
      }));
    },

    addReference: (reference: string) => {
      set((state) => ({
        linkForm: {
          ...state.linkForm,
          references: [...state.linkForm.references, reference],
        },
      }));
    },

    removeReference: (index: number) => {
      set((state) => ({
        linkForm: {
          ...state.linkForm,
          references: state.linkForm.references.filter((_, i) => i !== index),
        },
      }));
    },

    resetLinkForm: () => {
      set({
        linkForm: { linkedin: "", references: [] },
      });
    },

    setLinkedinInput: (value: string) => {
      set(() => ({
        linkedinInput: value,
      }));
    },

    resetLinkedinInput: () => {
      set(() => ({
        linkedinInput: "",
      }));
    },

    resetAll: () => {
      set({
        linkForm: { linkedin: "", references: [] },
        linkedinInput: "",
      });
    },
  }))
);

export default useLinkFormStore;
